// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//rootCloudUrl: 'http://globalinhouse.com:8080/encriss/v1/',
//rootCloudUrl: 'http://192.168.225.228:8080/encriss/v1/'
//rootCloudUrl: 'http://172.20.10.2:8080/encriss/v1/'

//storeId: 'adc28e0a-a422-4034-bc19-0f5a1c5e5ccf';
import { HttpHeaders } from '@angular/common/http';
export const environment = {
  production: false,
  // rootCloudUrl: 'https://ncr2.com/trg-v0.1/encriss/v1/',
  // storeId: '1ddb383e-0042-4afa-b6ff-e4963a78e0fd',

  // rootCloudUrl: 'https://ncr2.com/gmart-v0.1/encriss/v1/',
  // storeId: '52c425b0-f871-4e49-9ba4-685315d117a7',

  rootCloudUrl: 'https://api.gmart.in:8443/covidgum-v1.0/encriss/v1/',
  storeId: '74649480-3a0f-4220-bd63-ab778fb58be6',

  globalPushNotificationsTopic: 'ecommerce_app',
};

export const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

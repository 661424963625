import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment, httpOptions } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute
  ) { }
  currentUserProfile$: any;
  categories$: any = [];
  subCategories$: any = [];
  homePageDetails$: any = [];
  homePageSectionDetails$: any = [];
  heroBanner$: any = [];
  homeSectionCategories: any = [];
  newListing$: any = [];
  noListingProducts$: any = false;
  categoryId$: any;
  filteredCategories$: any = [];
  cart$: any = {};
  cartProducts$: any = [];
  headerSearchTerm$: any;

  getStoreHomePageDetails() {
    // let requestData = JSON.stringify({
    //   displayPage: 'Homepage',
    //   storeId: environment.storeId,
    //   token: {
    //     fingerprint: {
    //       createdAt: 0,
    //       deviceFingerprint: JSON.parse(
    //         localStorage.getItem('deviceFingerPrint')
    //       ),
    //       jsonOtherInfo: '',
    //       user_id: 0,
    //     },
    //     loginToken: '',
    //   },
    // });
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUserProfile$ = t;
    let requestData = JSON.stringify({
      deviceFingerprint: {
        createdAt: 0,
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
        jsonOtherInfo: '',
        user_id: 0,
      },
      loginToken: localStorage.getItem('loginToken'),
      storeId: environment.storeId,
    });
    const url = environment.rootCloudUrl + 'store/getUserWelcome';

    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  addNewProduct(cat, merchantStoreData) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUserProfile$ = t;

    let barcode = Math.floor(100000000 + Math.random() * 900000000);

    let requestData = {
      active: true,
      alernateId: barcode,
      availableQuantity: 100,
      brandName: 'ABZ' + Math.floor(100000000 + Math.random() * 900000000),
      categoryId: parseInt(cat.categoryId),
      categoryName: cat.categoryName,
      subCategoryname: cat.subCategory[0].subCategoryName,
      featuredProduct: false,
      hasOffer: false,
      hotSelling: false,
      hsnCode: Math.floor(100000000 + Math.random() * 900000000),
      interestPercentage: 0,
      imageUrl: '',
      listMedia: [],
      message: '',
      minBuyingQuantity: 1,
      new: true,
      newArrival: false,
      product: true,
      productAvailableQuantity: 100,
      productBarCode: barcode,
      productCreditDuration: 0,
      productDescription:
        'my description' + Math.floor(100000000 + Math.random() * 900000000),
      productHowToUse: '',
      productId: '',
      productMeasure: '',
      productMinSellingPrice: 50,
      productName:
        'MY NEW PRODUCT' + Math.floor(100000000 + Math.random() * 900000000),
      productOfferPrice: 120,
      productPurchasePrice: 50,
      productSellingPrice: 160,
      productShortDescription:
        Math.floor(100000000 + Math.random() * 900000000) + ' Hello',
      productTags: '',
      productWSP: 0,
      productWSPMinQuantity: 0,
      shortName: Math.floor(100000000 + Math.random() * 900000000),
      storeId: merchantStoreData.storeId,
      taxInclusive: false,
      taxExempted: true,
      taxOnPurchase: 0,
      token: {
        fingerprint: {
          deviceFingerprint: '',
          jsonOtherInfo: localStorage.getItem('deviceFingerPrint'),
        },
        loginToken: localStorage.getItem('loginToken'),
      },
      totalTaxPercentage: 0,
      trackInventory: true,
    };
    const url = environment.rootCloudUrl + 'store/addNewProduct';

    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  viewLoginUser(data) {
    //headers.append("Authorization", "Bearer " + t);
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUserProfile$ = t;
    let requestData = JSON.stringify({
      fingerprint: {
        createdAt: 0,
        deviceFingerprint: '',
        jsonOtherInfo: '',
        user_id: 0,
      },
      loginToken: data.loginToken,
    });
    const url = environment.rootCloudUrl + 'auth/viewProfile';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  registerMerchant(data: any): Observable<any> {
    let requestData = JSON.stringify({
      desiredRole: 'ROLE_MERCHANT',
      deviceFingerprint: {
        createdAt: 0,
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
        jsonOtherInfo: 'string',
        user_id: 0,
      },
      username: '9176270693',
      password: 'string',
      email: 'abc@gmail1.com',
      loginToken: localStorage.getItem('loginToken'),
      merchant: true,
    });
    const url = environment.rootCloudUrl + 'merchant/registerMerchant';

    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  registerUserAsMerchant(data: any): Observable<any> {
    let requestData = JSON.stringify({
      desiredRole: 'ROLE_MERCHANT',
      deviceFingerprint: {
        createdAt: 0,
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
        jsonOtherInfo: 'string',
        user_id: 0,
      },
      username: localStorage.getItem('usernameOrEmail'),
      password: 'string',
      email: 'abc@gmail1.com',
      loginToken: localStorage.getItem('loginToken'),
      merchant: true,
    });
    const url = environment.rootCloudUrl + 'merchant/registerUserAsMerchant';

    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  addStoreAddress(data: any): Observable<any> {
    let requestData = JSON.stringify({
      active: true,
      addLine1: data.addLine1,
      addLine2: data.addLine2,
      addState: data.addState,
      addressType: 'SHIPPING',
      city: data.city,
      country: data.country,
      createdAt: 0,
      lastUpdated: 0,
      lattitude: data.lattitude,
      longitude: data.longitude,
      deviceFingerprint: {
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
        jsonOtherInfo: '',
      },
      loginToken: localStorage.getItem('loginToken'),

      zipCode: data.zipCode,
    });
    const url = environment.rootCloudUrl + 'merchant/addStoreAddress';

    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  addNewStore(data: any): Observable<any> {
    let requestData = JSON.stringify({
      deviceFingerprint: {
        createdAt: 0,
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
        jsonOtherInfo: 'string',
        user_id: 0,
      },
      loginToken: localStorage.getItem('loginToken'),
      merchantPhoneNo: localStorage.getItem('usernameOrEmail'),
      phoneNumbers: data.phoneNumbers,
      storeAccessPassword: 'string',
      storeEmployees: data.storeEmployees,
      storeEnabled: true,
      storeName: data.storeName,
      storeOwnerName: data.storeOwnerName,
      storeRunningCost: data.storeRunningCost,
      storeType: 'Auto',
    });
    const url = environment.rootCloudUrl + 'merchant/addNewStore';
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  updateStoreTime(data: any): Observable<any> {
    let requestData = JSON.stringify({
      setWeekDay: data.setWeekDay,
      storeId: localStorage.getItem('storeId'),
      token: {
        fingerprint: {
          createdAt: 0,
          deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
          jsonOtherInfo: 'string',
          user_id: 0,
        },
        loginToken: localStorage.getItem('loginToken'),
      },
    });
    const url = environment.rootCloudUrl + 'merchant/updateStoreTime';
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  mapAddressToCart(data): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('addressId', data.addressId);
    formData.append('addressType', 'SHIPPING');
    formData.append('cartId', localStorage.getItem('cartId'));

    const url = environment.rootCloudUrl + 'store/mapAddressToCart';
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + localStorage.getItem('accessToken') }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  mapBillingAddressToCart(data): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('addressId', data.addressId);
    formData.append('addressType', 'BILLING');
    formData.append('cartId', localStorage.getItem('cartId'));

    const url = environment.rootCloudUrl + 'store/mapAddressToCart';
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + localStorage.getItem('accessToken') }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  uploadStoreProductsDownload(): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('loginToken', localStorage.getItem('loginToken'));
    formData.append('storeKey', localStorage.getItem('storeId'));
    formData.append('type', localStorage.getItem('download'));

    const url = environment.rootCloudUrl + 'merchant/uploadStoreProducts';
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + localStorage.getItem('accessToken') }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  export(link: any) {
    return this.http.get(link, { responseType: 'blob' });
  }

  uploadStoreProductsUpload(event: any): Observable<any> {
    const file = event.target.files[0];
    // const file1 = event.target.files;
    let formData: FormData = new FormData();
    formData.append('loginToken', localStorage.getItem('loginToken'));
    formData.append('storeKey', localStorage.getItem('storeId'));
    formData.append('file', file);

    const url = environment.rootCloudUrl + 'merchant/uploadStoreProducts';
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + localStorage.getItem('accessToken') }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  fetchHomeScreenSlides(data: any): Observable<any> {
    const url = environment + 'slides.php';
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUserProfile$ = t;

    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + localStorage.getItem('accessToken') }),
    };
    return this.http
      .get(url, httpOptions)
      .pipe(map((response: Response) => response));
  }
  getHomePageSectionDetails() {
    // var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // this.currentUserProfile$ = t;
    let requestData = JSON.stringify({
      fingerprint: {
        deviceFingerprint: '',
        jsonOtherInfo: '',
      },
      pageType: 'HomePage',
      storeId: environment.storeId,
    });
    const url = environment.rootCloudUrl + 'store/web/getPageMetaDataByStore';

    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ',
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  getPageSectionBySectionKey(data) {
    // var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // this.currentUserProfile$ = t;
    let requestData = JSON.stringify({
      fingerprint: {
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
        jsonOtherInfo: '',
      },
      pageType: 'HomePage',
      sectionKey: data.sectionKey,
      storeId: environment.storeId,
    });
    const url =
      environment.rootCloudUrl + 'store/web/getPageSectionBySectionKey';

    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ',
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  cancelBidRequest(data) {
    let formData: FormData = new FormData();
    formData.append('bidKey ', data.bidKey);
    const url = environment.rootCloudUrl + 'bidding/cancelBidRequest';
    // var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));

    const httpOptions = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + localStorage.getItem('accessToken') }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  acceptBidRequest(data) {
    let formData: FormData = new FormData();
    formData.append('bidKey ', data.bidKey);
    const url = environment.rootCloudUrl + 'bidding/acceptBidRequest';
    // var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));

    const httpOptions = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + localStorage.getItem('accessToken') }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  getOrderDetails(data) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));

    let formData = {
      orderId: data.id,
      token: {
        fingerprint: {
          deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
          jsonOtherInfo: '',
        },
        loginToken: localStorage.getItem('loginToken'),
      },
    };
    const url = environment.rootCloudUrl + 'store/getOrderDetails';

    const httpOptions = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + localStorage.getItem('accessToken') }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  initiatePayment(data) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));

    let formData = {
      cartId: localStorage.getItem('cartId'),
      paymentMode: 'OnlineCreditCard',
      provider: 'Stripe',
      storeId: environment.storeId,
      token: {
        fingerprint: {
          deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
          jsonOtherInfo: '',
        },
        loginToken: localStorage.getItem('loginToken'),
      },
    };

    const url = environment.rootCloudUrl + 'payments/initiatePayment';

    const httpOptions = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + localStorage.getItem('accessToken') }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  getOrders(data) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    data


    let formData = {
      customerId: '',
      pageNo: 0,
      pageSize: 8,
      sortOption: "orderCreationDate",
      sortOrder: "Desc",
      storeId: environment.storeId,
      requestType: data.type,
      token: {
        fingerprint: {
          deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
          jsonOtherInfo: '',
        },
        loginToken: localStorage.getItem('loginToken'),
      },
    };

    const url = environment.rootCloudUrl + 'store/getOrderSummary';

    const httpOptions = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + localStorage.getItem('accessToken') }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  getBidRequests(data) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));

    let formData = {
      bidFilter: {
        communicationStatus: data.status,
        primaryStatus: data.status,
        sortColumnName: 'lastUpdated',
        sortOrder: 'ASCENDING',
      },
      pageNo: 0,
      pageSize: 8,
      requestType: data.type,
      token: {
        fingerprint: {
          deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
          jsonOtherInfo: '',
        },
        loginToken: localStorage.getItem('loginToken'),
      },
    };

    const url = environment.rootCloudUrl + 'bidding/getBidRequests';

    const httpOptions = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + localStorage.getItem('accessToken') }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  getStoreCategories() {
    // const file1 = event.target.files;
    let formData: FormData = new FormData();
    formData.append('storeId', environment.storeId);
    formData.append('categoryId', '');
    const url = environment.rootCloudUrl + 'store/web/getCategoriesByStore';
    // var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  getStoreKey() {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // const file1 = event.target.files;
    const url = environment.rootCloudUrl + 'merchant/getStoreKey';
    // var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + localStorage.getItem('accessToken') }),
    };
    return this.http
      .get(url, httpOptions)
      .pipe(map((response: Response) => response));
  }

  getCategories() {
    this.getStoreCategories().subscribe((res: any) => {
      if (!res) {
        console.log(res);
        return;
      } else {
        this.categories$ = res.Categories;
        for (let i = 0; i < this.categories$.length; i++) {
          const element = this.categories$[i].subCategory;
          this.subCategories$ = element;
          this.subCategories$.sort((a, b) => {
            return Number(b.subCategoryId) - Number(a.subCategoryId);
          });
        }
        this.categories$.sort((a, b) => {
          return Number(a.categoryId) - Number(b.categoryId);
        });
      }
    });
  }
  getHomePageListSectionDetails() {
    this.getHomePageSectionDetails().subscribe((res: any) => {
      if (!res) {
        console.log(res);
        return;
      } else {
        this.homePageDetails$ = res.listSection;
        for (let i = 0; i < this.homePageDetails$.length; i++) {
          const element = this.homePageDetails$[i];
          this.getHomePageSectionBySectionKey(element.sectionKey);
        }
        setTimeout(() => {
          this.spinner.hide();
        }, 2000);
      }
    });
  }
  banners: any = {};
  getHomePageSectionBySectionKey(key: any) {
    let data = {
      sectionKey: key,
    };
    this.getPageSectionBySectionKey(data).subscribe((res: any) => {
      if (!res) {
        console.log(res);
        return;
      } else {
        this.homePageSectionDetails$.push(res);
        for (let i = 0; i < this.homePageSectionDetails$.length; i++) {
          const element = this.homePageSectionDetails$[i];
          // if (element['sectionType:1'] === '61ac28ce-bb59-4393-b4aa-83bc2b55457a') {
          //   this.homeSectionCategories = element;
          // }
          if (element['sectionType:1'] === 'CATEGORY_PAGE') {
            this.heroBanner$ = element;
            for (
              let i = 0;
              i < this.heroBanner$['HeroBannerCorousel:1'].length;
              i++
            ) {
              const element = this.heroBanner$['HeroBannerCorousel:1'][i];
              this.banners = element;
            }
          }
          if (element.sectionKey === 'bb8eb732-c38e-4c77-b2e9-c20ae5e8be5b') {
            this.newListing$ = element;
            if (this.newListing$.products.length === 0) {
              this.noListingProducts$ = true;
            } else {
              this.noListingProducts$ = false;
            }
          }
        }
        if (this.heroBanner$) {
          this.heroBanner$['HeroBannerCorousel:1'].sort((a, b) => {
            return a.displayOrder - b.displayOrder;
          });
          this.homePageSectionDetails$.sort((a, b) => {
            return b.verticalDisplayOrder - a.verticalDisplayOrder;
          });
        }
        setTimeout(() => {
          this.spinner.hide();
        }, 2000);
      }
    });
  }

  placeOrder(data) {
    let requestData = {
      amountPaid: parseFloat(parseFloat(data.payable).toFixed(2)),
      cartId: localStorage.getItem('cartId'),
      customerFullName:
        this.currentUserProfile$.userFirstName +
        ' ' +
        this.currentUserProfile$.userLastName,
      paymentMode: 'Cash',
      bidRequest: false,
      provider: 'COD',
      retCode: '',
      signatures: '',
      txnId: '',
      xnStatus: 'SUCCESS',
      deviceFingerprint: {
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
        jsonOtherInfo: '',
      },
      loginToken: localStorage.getItem('loginToken'),
    };
    const url = environment.rootCloudUrl + 'store/placeOrder';

    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    const httpOptions = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + localStorage.getItem('accessToken') }),
    };
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  placeStripeOrder(data) {
    let requestData = {
      amountPaid: 0,
      amountPending: parseFloat(parseFloat(data.payable).toFixed(2)),
      cartId: localStorage.getItem('cartId'),
      bidRequest: false,
      customerFullName:
        this.currentUserProfile$.userFirstName +
        ' ' +
        this.currentUserProfile$.userLastName,
      paymentMode: 'OnlineCreditCard',
      provider: 'Stripe',
      retCode: data.token.token.id,
      storeId: environment.storeId,
      signatures: '',
      txnId: data.txnId,
      xnStatus: 'INITIATED',
      deviceFingerprint: {
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
        jsonOtherInfo: '',
      },
      loginToken: localStorage.getItem('loginToken'),
    };

    const url = environment.rootCloudUrl + 'store/placeOrder';

    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    const httpOptions = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + localStorage.getItem('accessToken') }),
    };
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  placeBid(data) {
    let requestData = {
      bidPrice: data.bidPrice,
      lattitude: 0,
      longitude: 0,
      productId: data.productId,
      token: {
        fingerprint: {
          deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
          jsonOtherInfo: '',
        },
        loginToken: localStorage.getItem('loginToken'),
      },
    };
    const url = environment.rootCloudUrl + 'bidding/placeBid';

    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    const httpOptions = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + localStorage.getItem('accessToken') }),
    };
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  searchProduct(data) {
    let requestData = {
      categoryId: '',
      filter: data.filter,
      pageNo: parseInt(data.pageNo),
      pageSize:  parseInt(data.pageSize),
      searchString: '',
      sortOption: data.sortOption,
      sortOrder: data.sortOrder,
      storeId: data.storeId,
      filterEx: data.filterEx,
      sendAllProducts: data.sendAllProducts
    };
    const url = environment.rootCloudUrl + 'store/web/searchProduct';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ',
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  getProductById(data) {
    // const file1 = event.target.files;
    let formData: FormData = new FormData();
    formData.append('storeId', environment.storeId);
    formData.append('productId', data.productId);
    const url = environment.rootCloudUrl + 'store/web/getProductById';
    // var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  addMessageToCart(data) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // const file1 = event.target.files;
    let formData: FormData = new FormData();
    formData.append('customerMessage', data.message);
    formData.append('cartId', localStorage.getItem('cartId'));
    const url = environment.rootCloudUrl + 'store/addMessageToCart';
    // var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // this.currentUserProfile$ = t;
    //headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + localStorage.getItem('accessToken') }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  getProduuctsByCategoryId(data) {
    this.spinner.show();
    this.searchProductByCategoryId(data).subscribe((res) => {
      this.spinner.hide();
      if (res) {
        this.filteredCategories$ = res;
        this.filteredProducts$ = this.filteredCategories$.products;
        console.log(this.filteredProducts$, 'abkdjbkj');

        if (this.searchTerm$) {
          this.setFilteredItems();
        } else if (this.headerSearchTerm$) {
          this.headerFilteredItems();
        }
      } else {
        console.error(res);
        this.spinner.hide();
      }
    });
  }
  searchTerm$: any;
  filteredProducts$: any = [];
  setFilteredItems() {
    this.router.navigate(['/browse-listing/ALL']).then((res) => {
      if (!this.searchTerm$) {
        this.filteredProducts$ = this.filteredProducts$;
      }
      this.filteredProducts$ = this.filterItems(this.searchTerm$);
    });
  }

  filterItems(searchTerm: any) {
    return this.filteredCategories$.products.filter((item: any) => {
      return (
        item.productName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
        item.categoryName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
      );
    });
  }
  headerFilteredItems() {
    if (!this.headerSearchTerm$) {
      this.filteredProducts$ = this.filteredProducts$;
    }
    this.filteredProducts$ = this.filterItems(this.headerSearchTerm$);
  }

  headerfilterItems(searchTerm: any) {
    return this.filteredCategories$.products.filter((item: any) => {
      return (
        item.productName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
        item.categoryName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
      );
    });
  }
  searchProductByCategoryId(data) {
    let requestData = JSON.stringify({
      categoryId: data.categoryId,
      filter: data.filter,
      subCategoryId: data.subCategoryId,
      pageNo: parseInt(data.pageNo),
      pageSize:  parseInt(data.pageSize),
      searchString: data.searchString,
      sortOption: data.sortOption,
      sortOrder: data.sortOrder,
      storeId: environment.storeId,
      filterEx: data.filterEx,
      showAllProducts: data.showAllProducts,
      token: {
        fingerprint: {
          deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
          jsonOtherInfo: '',
        },
        loginToken: '',
      },
    });
    const url = environment.rootCloudUrl + 'store/web/searchProduct';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ',
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  addToCart(data) {

    let loginToken = localStorage.getItem('loginToken');
    let requestData = JSON.stringify({
      cartId: data.cartId,
      dealId: data.dealId,
      discountCode: data.discountCode,
      offer: data.offer,
      productId: data.productId,
      quantity: data.quantity,
      storeId: environment.storeId,
      token: {
        fingerprint: {
          createdAt: 0,
          deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
          jsonOtherInfo: '',
          user_id: 0,
        },
        loginToken: loginToken ? loginToken : '',
      },
    });
    const url = environment.rootCloudUrl + 'store/web/addToCart';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ',
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  totalCart$: any;


  viewCart() {
    if (!localStorage.getItem('cartId')) {
      this.totalCart$ = 0;
      this.cart$ = [];
      this.cartProducts$ = [];
      return;
    }

    let data = {
      cartId: localStorage.getItem('cartId'),
      paymentMode: 'Cash',
    };

    try {
      this.viewCartItems(data).subscribe((res: any) => {
        this.cart$ = res.Cart;

        this.cartProducts$ = res.Cart.cartItems.filter((item) => {
          return item.quantity !== 0;
        });
        console.log(this.cartProducts$);
        if (this.cartProducts$.length) {

          this.totalCart$ = this.cartProducts$
            .map((product) => product.quantity)
            .reduce((product, quantity) => quantity + product);
        } else {
          this.totalCart$ = 0;
        }
      },

        err => {
          this.totalCart$ = 0;
          this.cart$ = [];
          this.cartProducts$ = [];
          localStorage.removeItem('cartId');
        }
      )
    }
    catch (err) {
      this.totalCart$ = 0;
      this.cart$ = [];
      this.cartProducts$ = [];
    }
  }
  viewCartItems(data) {
    let loginToken = localStorage.getItem('loginToken');
    let requestData = JSON.stringify({
      cartId: data.cartId,
      paymentMode: data.paymentMode,
      storeId: environment.storeId,
      token: {
        fingerprint: {
          deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
          jsonOtherInfo: '',
        },
        loginToken: '',
      },
    });
    const url = environment.rootCloudUrl + 'store/web/viewCart';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ',
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response))
  }
  removeFormCart(data) {

    if (!localStorage.getItem('loginToken')) {
      return;
    }

    let loginToken = localStorage.getItem('loginToken');
    let requestData = JSON.stringify({
      cartId: data.cartId,
      discountCode: data.discountCode,
      quantity: data.quantity,
      isOffer: data.isOffer,
      dealId: '',
      productId: data.productId,
      storeId: environment.storeId,
      token: {
        fingerprint: {
          deviceFingerprint: '',
          jsonOtherInfo: '',
        },
        loginToken: '',
      },
    });
    const url = environment.rootCloudUrl + 'store/removeFromCart';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ',
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  addCustomerAddress(data) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    let loginToken = localStorage.getItem('loginToken');

    let requestData = JSON.stringify({
      addLine1: data.addLine1,
      addLine2: data.addLine2,
      addState: data.addState,
      addressType: data.addressType,
      cartId: localStorage.getItem('cartId'),
      city: data.city,
      country: data.country,
      customerFullName: data.customerFullName,
      storeId: environment.storeId,
      token: {
        fingerprint: {
          deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
          jsonOtherInfo: '',
        },
        loginToken: loginToken,
      },
      zipCode: data.zipCode,
    });
    const url = environment.rootCloudUrl + 'store/addCustomerAddress';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  getUserAddressList() {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    let loginToken = localStorage.getItem('loginToken');
    let requestData = JSON.stringify({
      deviceFingerprint: {
        jsonOtherInfo: '',
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
      },
      loginToken: loginToken,
    });
    const url = environment.rootCloudUrl + 'store/getUserAddressList';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  deleteUserAddress(data) {
    let loginToken = localStorage.getItem('loginToken');
    // const file1 = event.target.files;
    let requestData = JSON.stringify({
      deviceFingerprint: {
        jsonOtherInfo: '',
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
      },
      loginToken: loginToken,
    });

    const url =
      environment.rootCloudUrl +
      'store/removeUserAddress?addressId=' +
      data.addressId;
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // this.currentUserProfile$ = t;
    // headers.append("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  addNewStoreMerchant() {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    let loginToken = localStorage.getItem('loginToken');
    let requestData = JSON.stringify({
      deviceFingerprint: {
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
        jsonOtherInfo: '',
      },
      loginToken: loginToken,
      masterStore: false,
      merchantPhoneNo: localStorage.getItem('userMobileNumber'),
      phoneNumbers: [],
      storeAccessPassword: 'salvage_scout',
      storeEmailPrefix: 'salvage-',
      storeEmployees: [],
      storeEnabled: true,
      storeName: 'salvage_scout',
      storeOwnerName: t.userFirstName + ' ' + t.userLastName,
      storeRunningCost: 0,
      storeType: 'Auto',
    });
    const url = environment.rootCloudUrl + 'store/addCustomerAddress';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }


  updateUserProfilePic(event: any): Observable<any> {
    const file = event.target.files[0];
    // const file1 = event.target.files;
    let formData: FormData = new FormData();
    formData.append('loginToken', localStorage.getItem('loginToken'));
    formData.append('storeId', environment.storeId);
    formData.append('file', file);

    const url = environment.rootCloudUrl + 'store/updateUserProfilePic';
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    // const httpOptions = {
    //     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " +localStorage.getItem('accessToken') })
    // };
    const httpOptions = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + localStorage.getItem('accessToken') }),
    };
    return this.http
      .post(url, formData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  updateUserProfile(data) {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    let requestData = JSON.stringify({
      dob: data.dob,
      firstName: data.firstname,
      lastName: data.lastname,
      email: data.email,
      // password: data.password,
      // media: {
      //   displayOrder: 0,
      //   mediaTags: '',
      //   mediaType: 'IMAGE',
      //   mediaURL: data.picture,
      //   primary: true,
      // },
      storeId: environment.storeId,
      deviceFingerprint: {
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
        jsonOtherInfo: '',
      },
      merchant: false,
      loginToken: localStorage.getItem('loginToken'),
    });
    const url = environment.rootCloudUrl + 'auth/updateUserProfile';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-requests-browse',
  templateUrl: './requests-browse.component.html',
  styleUrls: ['./requests-browse.component.scss'],
})
export class RequestsBrowseComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}

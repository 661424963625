import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  selectedIndex: number = 0;
  constructor(public commonService: CommonService) { }

  ngOnInit() { }

  onTabChanged(event) {
    console.log(event.index)
    this.selectedIndex = event.index;
  }

}

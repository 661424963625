import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sell-item-shipping',
  templateUrl: './sell-item-shipping.component.html',
  styleUrls: ['./sell-item-shipping.component.scss', '../select-item/select-item.component.scss'],
})
export class SellItemShippingComponent implements OnInit {

  pickUpAddressToggle = false;
  TAB_SELECTED = "fedEx";
  constructor() { }

  ngOnInit() { }

  segmentChanged(event) {
    this.TAB_SELECTED = event.detail.value;
    console.log()
  }

  toggleDisplayDiv(){
    console.log("Before click: "+ this.pickUpAddressToggle);
    this.pickUpAddressToggle = !this.pickUpAddressToggle;
    console.log("After click: "+ this.pickUpAddressToggle);
  }

}

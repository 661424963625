import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'tabs',
    pathMatch: 'full',
  },
  {
    path: 'home',
    redirectTo: 'tabs/home',
    pathMatch: 'full',
  },
  {
    path: 'catalog',
    redirectTo: 'tabs/catalog',
    pathMatch: 'full',
  },
  {
    path: 'cart',
    redirectTo: 'tabs/cart',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'browse-listing/:categoryId',
    loadChildren: () =>
      import('./pages/browse-listing/browse-listing.module').then(
        (m) => m.BrowseListingPageModule
      ),
  },
  {
    path: 'browse-listing/:categoryId/:subCategoryId',
    loadChildren: () =>
      import('./pages/browse-listing/browse-listing.module').then(
        (m) => m.BrowseListingPageModule
      ),
  },
  {
    path: 'list-detail',
    loadChildren: () =>
      import('./pages/list-detail/list-detail.module').then(
        (m) => m.ListDetailPageModule
      ),
  },
  {
    path: 'tabs',
    loadChildren: () =>
      import('./pages/my-listing/my-listing.module').then(
        (m) => m.MyListingPageModule
      ),
  },
  {
    path: 'create-request',
    loadChildren: () =>
      import('./pages/create-request/create-request.module').then(
        (m) => m.CreateRequestPageModule
      ),
  },
  {
    path: 'checkout',
    loadChildren: () =>
      import('./pages/checkout/checkout.module').then(
        (m) => m.CheckoutPageModule
      ),
  },
  {
    path: 'checkout-message-seller',
    loadChildren: () =>
      import(
        './pages/checkout-message-seller/checkout-message-seller.module'
      ).then((m) => m.CheckoutMessageSellerPageModule),
  },
  {
    path: 'order-sucess/:id',
    loadChildren: () =>
      import('./pages/order-sucess/order-sucess.module').then(
        (m) => m.OrderSucessPageModule
      ),
  },
  {
    path: 'order-sucess',
    loadChildren: () =>
      import('./pages/order-sucess/order-sucess.module').then(
        (m) => m.OrderSucessPageModule
      ),
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then( m => m.FaqPageModule)
  },
  {
    path: 'terms-conditions',
    loadChildren: () => import('./pages/terms-conditions/terms-conditions.module').then( m => m.TermsConditionsPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./pages/contact-us/contact-us.module').then( m => m.ContactUsPageModule)
  },
  {
    path: 'about-us',
    loadChildren: () => import('./pages/about-us/about-us.module').then( m => m.AboutUsPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

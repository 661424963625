import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment, httpOptions } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private store: StoreService,
    private spinner: NgxSpinnerService
  ) { }
  currentUser$: any;
  register(data: any): Observable<any> {
    let requestData = JSON.stringify({
      desiredRole: 'ROLE_USER',
      deviceFingerprint: {
        createdAt: 0,
        deviceFingerprint: JSON.parse(
          localStorage.getItem('deviceFingerPrint')
        ),
        jsonOtherInfo: '',
        user_id: 0,
      },
      email: data.email,
      fullname: data.fullname,
      loginToken: '',
      merchant: false,
      password: data.password,
      storeId: environment.storeId,
      username: data.phone,
    });
    const url = environment.rootCloudUrl + 'auth/register';
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }
  registerAsMerchant(data: any): Observable<any> {
    let requestData = JSON.stringify({
      desiredRole: 'ROLE_MERCHANT',
      deviceFingerprint: {
        deviceFingerprint: JSON.parse(
          localStorage.getItem('deviceFingerPrint')
        ),
        jsonOtherInfo: '',
      },
      email: data.email,
      loginToken: data.loginToken,
      merchant: true,
      password: data.password,
      username: data.username,
    });
    const url = environment.rootCloudUrl + 'merchant/registerMerchant';
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));

    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' +localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, requestData, httpOptions1)
      .pipe(map((response: Response) => response));
  }

  registerUserAsMerchant(data: any): Observable<any> {
    let requestData = JSON.stringify({
      desiredRole: 'ROLE_MERCHANT',
      deviceFingerprint: {
        deviceFingerprint: JSON.parse(
          localStorage.getItem('deviceFingerPrint')
        ),
        jsonOtherInfo: '',
      },
      email: data.email,
      loginToken: data.loginToken,
      merchant: true,
      password: data.password,
      username: data.username,
      storeId: environment.storeId,
    });
    const url = environment.rootCloudUrl + 'merchant/registerUserAsMerchant';
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));

    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' +localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, requestData, httpOptions1)
      .pipe(map((response: Response) => response));
  }

  addNewStore(data: any): Observable<any> {
    let requestData = JSON.stringify({
      deviceFingerprint: {
        createdAt: 0,
        deviceFingerprint: JSON.parse(
          localStorage.getItem('deviceFingerPrint')
        ),
        jsonOtherInfo: '',
      },
      loginToken: data.loginToken,
      masterStore: false,
      storeEmailPrefix: 'salvage-',
      merchantPhoneNo: data.username,
      phoneNumbers: [],
      storeEmployees: [],

      storeAccessPassword: data.password,
      storeEnabled: true,
      storeName: data.username + ' - STORE',
      storeOwnerName: data.username,
      storeRunningCost: 0,
      storeType: 'Auto',
    });

    const url = environment.rootCloudUrl + 'merchant/addNewStore';
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));

    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' +localStorage.getItem('accessToken'),
      }),
    };
    return this.http
      .post(url, requestData, httpOptions1)
      .pipe(map((response: Response) => response));
  }

  verifyOtp(data: any): Observable<any> {
    let requestData = JSON.stringify(
      {
        password: data.newPassword,
        phoneNumber: data.mobile,
        token: data.otp,
        storeId: environment.storeId,
        deviceFingerprint: {
          deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
          jsonOtherInfo: '',
        },
      }
      //   {
      //   deviceFingerprint: {
      //     createdAt: 0,
      //     deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
      //     jsonOtherInfo: 'string',
      //     user_id: 0,
      //   },
      //   password: '',
      //   phoneNumber: data.mobile,
      //   storeId: environment.storeId,
      //   token: data.otp,
      // }
    );

    const url = environment.rootCloudUrl + 'auth/verifyOtp';
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  login(data: any): Observable<any> {
    let requestData = JSON.stringify({
      password: data.password,
      storeId: environment.storeId,
      usernameOrEmail: data.usernameOrEmail,
    });
    const url = environment.rootCloudUrl + 'auth/login';
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  validateToken(data: any): Observable<any> {
    let requestData = JSON.stringify({
      fingerprint: {
        createdAt: 0,
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
        jsonOtherInfo: 'string',
        user_id: 0,
      },
      loginToken: data.loginToken,
    });
    const url = environment.rootCloudUrl + 'auth/validateToken';
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  passwordReset(data: any): Observable<any> {
    let requestData = JSON.stringify(
      {
        phoneNumber: data.phoneNumber,
        password: data.password,
        storeId: environment.storeId,
        token: data.token,
        deviceFingerprint: {
          deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
          jsonOtherInfo: '',
        },
      }
      //   {
      //   deviceFingerprint: {
      //     createdAt: 0,
      //     deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
      //     jsonOtherInfo: 'string',
      //     user_id: 0,
      //   },
      //   storeId: localStorage.getItem('storeId'),
      //   password: data.password,
      //   phoneNumber: data.mobile,
      //   token: data.token,
      // }
    );
    const url = environment.rootCloudUrl + 'auth/passwordReset';
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  sendOtp(data: any): Observable<any> {
    let requestData = JSON.stringify({
      deviceFingerprint: {
        createdAt: 0,
        deviceFingerprint: localStorage.getItem('deviceFingerPrint'),
        jsonOtherInfo: 'string',
        user_id: 0,
      },
      storeId: environment.storeId,
      password: '',
      phoneNumber: data.mobile,
      token: '',
    });
    const url = environment.rootCloudUrl + 'auth/sendOtp';
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }

  logout() {
    this.spinner.show();
    localStorage.removeItem('currentUserProfile');
    localStorage.removeItem('loginToken');
    localStorage.removeItem('cartId');
    this.store.cartProducts$ = [];
    this.store.totalCart$ = 0;
    this.router.navigate(['/tabs/login']).then((res) => {
      this.currentUser$ = null;
      this.store.getCategories();
      this.store.getHomePageListSectionDetails();
      this.store.viewCart();
      this.spinner.hide();
    });
    // window.location.reload();
  }
  getCurrentUser() {
    let data = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.currentUser$ = data;
    // console.log(this.currentUser);
    // this.store.viewLoginUser(data).subscribe((res) => {
    //   this.currentUser$ = res;
    // });
    // this.store.getCategories();
    // this.store.getHomePageListSectionDetails();
  }
  // var t = localStorage.getItem("accessToken");
  // headers.append("Authorization", "Bearer " + t);
  // var body = JSON.stringify(user);

  viewUserProfile(data: any): Observable<any> {
    let requestData = JSON.stringify({
      deviceFingerprint: {
        createdAt: 0,
        deviceFingerprint: JSON.parse(
          localStorage.getItem('deviceFingerPrint')
        ),
        jsonOtherInfo: '',
      },
      loginToken: data.loginToken,
    });
    const url = environment.rootCloudUrl + 'auth/viewProfile';
    return this.http
      .post(url, requestData, httpOptions)
      .pipe(map((response: Response) => response));
  }
}

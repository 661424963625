import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss'],
})
export class InboxComponent implements OnInit {
  displayedColumns: string[] = ['eamil', 'name', 'date', 'message', 'remove'];
  dataSource;
  selectedValue = 'Inbox';
  isOpenMail: boolean = false;

  constructor(public commonService: CommonService) {}

  ngOnInit() {
    this.dataSource = [
      {
        eamil: 'Freightliner Classic XL Hood',
        name: 'John Roberts',
        date: '02/02/2021',
        message:
          'Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        slice: 39,
        moreOption: '',
      },
      {
        eamil: 'Freightliner Classic XL Hood',
        name: 'John Roberts',
        date: '02/02/2021',
        message:
          'Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        slice: 39,
      },
      {
        eamil: 'Freightliner Classic XL Hood',
        name: 'John Roberts',
        date: '02/02/2021',
        message:
          'Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        slice: 39,
      },
      {
        eamil: 'Freightliner Classic XL Hood',
        name: 'John Roberts',
        date: '02/02/2021',
        message:
          'Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        slice: 39,
      },
      {
        eamil: 'Freightliner Classic XL Hood',
        name: 'John Roberts',
        date: '02/02/2021',
        message:
          'Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        slice: 39,
      },
      {
        eamil: 'Freightliner Classic XL Hood',
        name: 'John Roberts',
        date: '02/02/2021',
        message:
          'Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        slice: 39,
      },
      {
        eamil: 'Freightliner Classic XL Hood',
        name: 'John Roberts',
        date: '02/02/2021',
        message:
          'Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        slice: 39,
      },
      {
        eamil: 'Freightliner Classic XL Hood',
        name: 'John Roberts',
        date: '02/02/2021',
        message:
          'Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        slice: 39,
      },
      {
        eamil: 'Freightliner Classic XL Hood',
        name: 'John Roberts',
        date: '02/02/2021',
        message:
          'Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        slice: 39,
      },
      {
        eamil: 'Freightliner Classic XL Hood',
        name: 'John Roberts',
        date: '02/02/2021',
        message:
          'Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        slice: 39,
      },
    ];
  }
  // jbafds
  showMore(index) {
    if (this.dataSource[index].slice == -100) {
      this.dataSource[index].slice = 39;
    } else {
      this.dataSource[index].slice = -100;
    }
  }

  selectReportType(event) {
    this.selectedValue = event.detail.value;
  }

  openMailBox() {
    this.isOpenMail == true
      ? (this.isOpenMail = false)
      : (this.isOpenMail = true);
  }

  closeMailBox() {
    this.isOpenMail = false;
  }
}

import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { StoreService } from 'src/app/services/store.service';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Lightbox } from 'ngx-lightbox';
import { Product } from 'src/app/models/product.model';
// import { StorageService } from 'src/app/services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-listing-section',
  templateUrl: './listing-section.component.html',
  styleUrls: ['./listing-section.component.scss'],
})
export class ListingSectionComponent implements OnInit {

  collection = [];
  p: any;
  constructor(
    public modalController: ModalController,
    private router: Router,
    public toastr: ToastrService,

    private activatedRoute: ActivatedRoute,
    public store: StoreService
  ) { }
  rate = 5;

  selectedView: string = 'Block';
  categoryId: any;
  subCategoryId: any;
  filterText: any;
  searchInput: any;
  ngOnInit() {
    this.activatedRoute.params.subscribe((res) => {
      this.categoryId = res.categoryId;
      this.subCategoryId = res.subCategoryId;
      if (this.categoryId === 'ALL') {
        this.getAllProducts();
      } else {
        this.getProducts();
      }
    });
  }

  getProducts() {
    let data = {
      categoryId: this.categoryId,
      filter: 'HotSelling',
      subCategoryId: this.subCategoryId,
      pageNo: '0',
      pageSize: '1000',
      searchString: '',
      sortOption: 'productId',
      sortOrder: 'ASC',
    };
    this.store.getProduuctsByCategoryId(data);
  }
  getAllProducts() {
    let data = {
      categoryId: '',
      filter: 'SearchByTagOnly',
      subCategoryId: '',
      pageNo: '0',
      pageSize: '1000',
      searchString: '',
      sortOption: 'productId',
      sortOrder: 'ASC',
      sendAllProducts: false
    };

    if (this.categoryId === 'ALL') {
      data.sendAllProducts = true;
      data.filter = "NewArrival";
    }
    this.store.getProduuctsByCategoryId(data);
  }

  async presentModal() {
    const modal = await this.modalController.create({
      component: FilterPanelComponent,
      cssClass: 'my-custom-class',
    });
    return await modal.present();
  }
  filterItems: any = [
    { title: 'Featured', value: 'Featured' },
    { title: 'Hot Selling', value: 'HotSelling' },
    { title: 'New Arrival', value: 'NewArrival' },
  ];
  filterTitle: any = 'Most Relevant';
  categoryFilter(item: any) {
    this.filterTitle = item.title;
    let data = {
      categoryId: '',
      filter: item.value,
      subCategoryId: '',
      pageNo: '0',
      pageSize: '1000',
      searchString: '',
      sortOption: 'productId',
      sortOrder: 'ASC',
    };
    this.store.getProduuctsByCategoryId(data);
  }
  clearFilter() {
    this.router.navigate(['/browse-listing/ALL']).then((res) => {
      this.filterTitle = 'Filter';

      this.store.searchTerm$ = '';
      this.store.headerSearchTerm$ = '';
      return this.getAllProducts();
    });
  }
  gotoViewProduct(item): void {
    const queryParams: any = {};
    const arrayOfValues = item;

    queryParams.myArray = JSON.stringify(arrayOfValues);
    const navigationExtras: NavigationExtras = {
      queryParams,
    };
    this.router.navigate(['/list-detail'], navigationExtras);
  }
  onValChange(ev) {
    this.selectedView = ev;
    console.log(ev);
  }

  addToCart(item) {
    let cartId = localStorage.getItem('cartId');
    let data = {
      cartId: cartId ? cartId : '',
      dealId: item.dealId ? item.dealId : '',
      discountCode: item.discountCode
        ? item.discountCode
        : '',
      offer: item.offer ? item.offer : '',
      productId: item.productId,
      quantity: 1,
    };
    this.store.addToCart(data).subscribe((res: any) => {
      if (res) {
        localStorage.setItem('cartId', res.cartId);
        this.store.viewCart();
        this.toastr.success('Added to cart!');
      }
    });
  }
  prodCount: any = 0;
  checkInCart(product: any) {
    let prodCount;
    if (this.store.cartProducts$ && this.store.cartProducts$.length) {
      for (var i = 0; i < this.store.cartProducts$.length; i++) {
        if (
          product.productId === this.store.cartProducts$[i].productDto.productId
        ) {
          const element = this.store.cartProducts$[i];
          prodCount = element.quantity;
        }
      }
    } else {
      prodCount = 0;
    }
    return prodCount;
  }
  addQuantity(product) {
    let cartId = localStorage.getItem('cartId');
    let prodQuantity: any;
    for (let i = 0; i < this.store.cartProducts$.length; i++) {
      if (
        product.productId === this.store.cartProducts$[i].productDto.productId
      ) {
        prodQuantity = this.store.cartProducts$[i];
      }
    }
    if (prodQuantity) {
      let data = {
        cartId: cartId ? cartId : '',
        dealId: product.dealId ? product.dealId : '',
        discountCode: product.discountCode ? product.discountCode : '',
        offer: product.offer ? product.offer : '',
        productId: product.productId,
        quantity: ++prodQuantity.quantity,
      };
      this.store.addToCart(data).subscribe((res: any) => {
        if (res) {
          this.store.viewCart();
          this.toastr.success('Added to cart!');
        }
      });
    }
  }
  minusQuantity(product) {
    let cartId = localStorage.getItem('cartId');
    let prodQuantity: any;
    for (let i = 0; i < this.store.cartProducts$.length; i++) {
      if (
        product.productId === this.store.cartProducts$[i].productDto.productId
      ) {
        prodQuantity = this.store.cartProducts$[i];
      }
    }
    if (prodQuantity) {
      let i = prodQuantity.quantity;
      if (i !== 0) {
        i--;
        i = i;
        let data = {
          cartId: cartId ? cartId : '',
          dealId: product.dealId ? product.dealId : '',
          discountCode: product.discountCode ? product.discountCode : '',
          offer: product.offer ? product.offer : '',
          productId: product.productId,
          quantity: i,
        };
        this.store.addToCart(data).subscribe((res: any) => {
          if (res) {
            this.toastr.error('Removed form cart!');
            this.store.viewCart();
          }
        });
      }
    }
  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-mail-box',
  templateUrl: './mail-box.component.html',
  styleUrls: ['./mail-box.component.scss'],
})
export class MailBoxComponent implements OnInit {
  @Output() closeMailBox = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  goBack() {
    this.closeMailBox.emit();
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  constructor(public store: StoreService, public router: Router) {}

  ngOnInit() {}
  onSubmit() {
    this.router.navigate(['/browse-listing/ALL']);
  }
  clear() {
    this.store.searchTerm$ = '';
    this.store.setFilteredItems();
  }
}

//httpConfig.interceptor.ts
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from './services/auth.service';
import { ToastController } from '@ionic/angular';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  constructor(
    public auth: AuthService,

    public toastController: ToastController,) { }

  async warningToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      cssClass: 'custom-warning-toast',
    });
    toast.present();
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          // client-side error or network error
        } else {
          // TODO: Clean up following by introducing method
          if (error.status === 401) {
            this.auth.logout();
            return;
          }
          if (error.status === 400) {
            // TODO: Destroy local session; redirect to /login
            // TODO: Permission denied; show toast

            this.warningToast(error?.error ? JSON.stringify(error?.error) : "SOMETHING WENT WRONG")

          }
        }
        return throwError(error);
      })
    );
  }


}
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReportListingModelComponent } from '../report-listing-model/report-listing-model.component';

@Component({
  selector: 'app-order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.scss'],
})
export class OrderItemComponent implements OnInit {

  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit() { }

  report() {
    const dialogRef = this.dialog.open(ReportListingModelComponent, {
      width: '860px',
      data: { title: 'Cancel purchase' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}

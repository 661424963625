import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-id-check',
  templateUrl: './id-check.component.html',
  styleUrls: ['./id-check.component.scss'],
})
export class IdCheckComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}

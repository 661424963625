import { Component, OnInit, ViewChild } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { IonSlides } from '@ionic/angular';


@Component({
  selector: 'app-browse-by-category',
  templateUrl: './browse-by-category.component.html',
  styleUrls: ['./browse-by-category.component.scss'],
})
export class BrowseByCategoryComponent implements OnInit {

  @ViewChild('slideWithNav2', { static: false }) slideWithNav2: IonSlides;
  

  slideOptsTwo = {
    loop: true,
    navigation: true,
    autoplay:true,
  };

  constructor(public store: StoreService) { }

  ngOnInit() { }
  next(object, slideView) {
    slideView.slideNext();
    // console.log('Next');
  }

  prev(object, slideView) {
    slideView.slidePrev();
    // console.log('Prev');
  }
}

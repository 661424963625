import { Component, OnInit, ViewChild } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { IonSlides } from '@ionic/angular';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { MatDialog } from '@angular/material/dialog';
import { Lightbox } from 'ngx-lightbox';
import { Product } from 'src/app/models/product.model';
// import { StorageService } from 'src/app/services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-newest-listings',
  templateUrl: './newest-listings.component.html',
  styleUrls: ['./newest-listings.component.scss'],
})
export class NewestListingsComponent implements OnInit {
  @ViewChild('slideWithNav2', { static: false }) slideWithNav2: IonSlides;

  slideOpts = {
    slidesPerView: 'auto',
    loopedSlides: '3',
    loopPreventsSlide: true,
    loop: true,
    navigation: true,
    autoplay: true,
  };

  next(object, slideView) {
    slideView.slideNext();
    // console.log('Next');
  }

  prev(object, slideView) {
    slideView.slidePrev();
    // console.log('Prev');
  }

  constructor(public store: StoreService, private router: Router, public toastr: ToastrService,
    ) {}
  hotSellingProducts: any = [];
  ngOnInit() {
    this.getProducts();
  }
  getProducts() {
    let data = {
      categoryId: '',
      filter: 'HotSelling',
      pageNo: '0',
      pageSize: '6',
      searchString: '',
      sortOption: 'productId',
      sortOrder: 'DESC',
    };
    this.store.searchProductByCategoryId(data).subscribe((res: any) => {
      this.hotSellingProducts = res.products;
      console.log(this.hotSellingProducts, 'skgldf');
    });
  }
  gotoViewProduct(item): void {
    const queryParams: any = {};
    const arrayOfValues = item;

    queryParams.myArray = JSON.stringify(arrayOfValues);
    const navigationExtras: NavigationExtras = {
      queryParams,
    };
    this.router.navigate(['/list-detail'], navigationExtras);
  }


  addToCart(item) {
    let cartId = localStorage.getItem('cartId');
    let data = {
      cartId: cartId ? cartId : '',
      dealId: item.dealId ? item.dealId : '',
      discountCode: item.discountCode
        ? item.discountCode
        : '',
      offer: item.offer ? item.offer : '',
      productId: item.productId,
      quantity: 1,
    };
    this.store.addToCart(data).subscribe((res: any) => {
      if (res) {
        localStorage.setItem('cartId', res.cartId);
        this.store.viewCart();
        this.toastr.success('Added to cart!');
      }
    });
  }
  prodCount: any = 0;
  checkInCart(product: any) {
    let prodCount;
    if (this.store.cartProducts$ && this.store.cartProducts$.length) {
      for (var i = 0; i < this.store.cartProducts$.length; i++) {
        if (
          product.productId === this.store.cartProducts$[i].productDto.productId
        ) {
          const element = this.store.cartProducts$[i];
          prodCount = element.quantity;
        }
      }
    } else {
      prodCount = 0;
    }
    return prodCount;
  }
  addQuantity(product) {
    let cartId = localStorage.getItem('cartId');
    let prodQuantity: any;
    for (let i = 0; i < this.store.cartProducts$.length; i++) {
      if (
        product.productId === this.store.cartProducts$[i].productDto.productId
      ) {
        prodQuantity = this.store.cartProducts$[i];
      }
    }
    if (prodQuantity) {
      let data = {
        cartId: cartId ? cartId : '',
        dealId: product.dealId ? product.dealId : '',
        discountCode: product.discountCode ? product.discountCode : '',
        offer: product.offer ? product.offer : '',
        productId: product.productId,
        quantity: ++prodQuantity.quantity,
      };
      this.store.addToCart(data).subscribe((res: any) => {
        if (res) {
          this.store.viewCart();
          this.toastr.success('Added to cart!');
        }
      });
    }
  }
  minusQuantity(product) {
    let cartId = localStorage.getItem('cartId');
    let prodQuantity: any;
    for (let i = 0; i < this.store.cartProducts$.length; i++) {
      if (
        product.productId === this.store.cartProducts$[i].productDto.productId
      ) {
        prodQuantity = this.store.cartProducts$[i];
      }
    }
    if (prodQuantity) {
      let i = prodQuantity.quantity;
      if (i !== 0) {
        i--;
        i = i;
        let data = {
          cartId: cartId ? cartId : '',
          dealId: product.dealId ? product.dealId : '',
          discountCode: product.discountCode ? product.discountCode : '',
          offer: product.offer ? product.offer : '',
          productId: product.productId,
          quantity: i,
        };
        this.store.addToCart(data).subscribe((res: any) => {
          if (res) {
            this.toastr.error('Removed form cart!');
            this.store.viewCart();
          }
        });
      }
    }
  }
}

import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StoreService } from 'src/app/services/store.service';
import { MatDialog } from '@angular/material/dialog';
import { ReportListingModelComponent } from '../../report-listing-model/report-listing-model.component';
import { AddressDialogComponent } from '../../address-dialog/address-dialog.component';
// import { Stripe } from '@ionic-native/stripe/ngx';
declare var Stripe;
@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit {
  stripe = null;
  card: any;

  cardNumber: string;
  cardMonth: number;
  cardYear: number;
  cardCVV: string;

  isSellerPage: boolean = false;
  productDetails: any;

  paymentDetails: [];
  constructor(public location: Location, public store: StoreService, private toastr: ToastrService,
    private router: Router) {
    this.store.viewCart();
    this.initiatePayment();

  }

  ngOnInit() {
    this.initiatePayment();

    this.location.path() == '/tabs/checkout-message-seller'
      ? (this.isSellerPage = true)
      : (this.isSellerPage = false);

    console.log();
  }
  placeOrder() {
    let data = {
      payable: this.store.cart$.netPayable
    }
    try {
      this.store.placeOrder(data).subscribe((res: any) => {
        this.store.viewCart();
        this.router.navigate(['/tabs/order-sucess', res.orderId]);
      })
    } catch (err) {
      this.store.viewCart();
      this.router.navigate(['/home']);
    } finally {
     // this.store.viewCart();
      //this.router.navigate(['/home']);
    }
  }
  addToCart() {
    let cartId = localStorage.getItem('cartId');
    let data = {
      cartId: cartId ? cartId : '',
      dealId: this.productDetails.dealId ? this.productDetails.dealId : '',
      discountCode: this.productDetails.discountCode
        ? this.productDetails.discountCode
        : '',
      offer: this.productDetails.offer ? this.productDetails.offer : '',
      productId: this.productDetails.productId,
      quantity: 1,
    };
    this.store.addToCart(data).subscribe((res: any) => {
      localStorage.setItem('cartId', res.cartId);
      this.store.viewCart();
    }, err => {
      this.store.totalCart$ = 0;
      this.store.cart$ = [];
      this.store.cartProducts$ = [];
      localStorage.removeItem('cartId');
    });
  }
  prodCount: any;
  checkInCart(product: any) {
    if (this.store.cartProducts$ && this.store.cartProducts$.length) {
      for (var i = 0; i < this.store.cartProducts$.length; i++) {
        if (
          product.productId === this.store.cartProducts$[i].productDto.productId
        ) {
          const element = this.store.cartProducts$[i];
          this.prodCount = element.quantity;
        }
      }
    } else {
      this.prodCount = 0;
    }
    return this.prodCount;
  }


  setupStripe() {
    let elements = this.stripe.elements();
    var style = {
      base: {
        color: '#32325d',
        lineHeight: '24px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    this.card = elements.create('card', { style: style });

    this.card.mount('#card-element');

    this.card.addEventListener('change', event => {
      var displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    var form = document.getElementById('payment-form');
    form.addEventListener('submit', event => {
      event.preventDefault();

      // // this.stripe.createToken(this.card)
      // this.stripe.createSource(this.card).then(result => {
      //   if (result.error) {
      //     var errorElement = document.getElementById('card-errors');
      //     errorElement.textContent = result.error.message;
      //   } else {
      //     console.log(result);
      //   }
      // });

      this.validateCard();
    });
  }

  public submitGiftMessageForm(form: any) {
    this.store.addMessageToCart(form.value).subscribe((res) => {
      this.store.cart$ = res['Cart'];
      this.toastr.success('Delivery instructions updated!');
    });
  }
  initiatePayment() {
    if (!JSON.parse(localStorage.getItem('currentUserProfile'))) {
      return;
    }
    let data = {

    }
    this.store.initiatePayment(data).subscribe((res: any) => {
      if (res) {
        console.log(res);
        this.paymentDetails = res;

        localStorage.setItem('txnId', this.paymentDetails['TransactionNumber']);

        this.stripe = Stripe(this.paymentDetails['StripePublicKey']);

        this.setupStripe();
        return;
      } else {
      }
    });
  }

  validateCard() {

    const userAddress = JSON.parse(localStorage.getItem('userAddress'));
    if (!JSON.parse(localStorage.getItem('userAddress'))) {
      this.toastr.error('Please select valid shipping address!');
      return;
    }
    const data = {
      name: userAddress.customerFullName,
      address_line1: userAddress.addLine1,
      address_line2: userAddress.addLine2,
      address_city: userAddress.city,
      address_state: userAddress.addState,
      address_zip: userAddress.zipCode,
      address_country: "MX"
    }
    this.stripe.createToken(this.card, data)
      .then((token) => {
        console.log(token)
        let data = {
          payable: this.store.cart$.netPayable,
          token: token,
          txnId: localStorage.getItem('txnId')
        }

        try {
          this.store.placeStripeOrder(data).subscribe((res: any) => {
            localStorage.removeItem('cartId');
            this.store.viewCart();
            this.router.navigate(['/tabs/order-sucess', res.orderId]);
          })
        } catch (err) {
          // this.store.viewCart();
          // this.router.navigate(['/home']);
          this.toastr.error('Something went wrong!');
        } finally {
          // this.store.viewCart();
          //   this.router.navigate(['/home']);
        //  this.toastr.error('Something went wrong!');
        }


      })
      .catch(error => console.error(error));
  }
}

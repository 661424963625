export var single = [
    {
        "name": "2018",
        "value": 130000
    },
    {
      "name": "2019",
      "value": 230000
    },
    {
      "name": "2020",
      "value": 100000
    },
    {
      "name": "2021",
      "value": 340000
    }
  ];
  
  export var multi = [
    {
      "name": "Germany",
      "series": [
        {
          "name": "2010",
          "value": 7300000
        },
        {
          "name": "2011",
          "value": 8940000
        }
      ]
    },
  
    {
      "name": "USA",
      "series": [
        {
          "name": "2010",
          "value": 7870000
        },
        {
          "name": "2011",
          "value": 8270000
        }
      ]
    },
  
    {
      "name": "France",
      "series": [
        {
          "name": "2010",
          "value": 5000002
        },
        {
          "name": "2011",
          "value": 5800000
        }
      ]
    }
  ];
  
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { ReportListingModelComponent } from '../../report-listing-model/report-listing-model.component';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { StoreService } from 'src/app/services/store.service';
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
  ORDER_ITEM = 'PURCHASED';
  closeItemIsOpen: boolean = true;
  openItemIsOpen: boolean = true;
  pendingItemIsOpen: boolean = true;
  completedItemIsOpen: boolean = true;

  constructor(public commonService: CommonService, public dialog: MatDialog,


    private router: Router,
    private auth: AuthService,
    public store: StoreService,
    public toastController: ToastController,
    private spinner: NgxSpinnerService,
    public toaster: ToastrService

  ) { }

  ngOnInit() {
    this.getBuyOrders();

  }

  segmentChanged(ev) {
    this.ORDER_ITEM = ev.detail.value;
  }

  openCloseOpenItem() { }
  report() {
    const dialogRef = this.dialog.open(ReportListingModelComponent, {
      width: '860px',
      data: { title: 'Cancel purchase' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }



  buyOrders = [];
  buyClosedOrders = [];
  sellOrders = [];
  sellClosedOrders = []
  async successToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      cssClass: 'custom-success-toast',
    });
    toast.present();
  }
  getBuyOrders() {
    let data = {
      "customerId": '',
      "pageNo": 0,
      "pageSize": 8,
      "sortOption": "orderCreationDate",
      "sortOrder": "Desc",
    }
    this.store.getOrders(data).subscribe((res: any) => {
      if (res) {
        console.log(res);
        this.buyOrders = res.content;
        // this.successToast('BUY BID LIST FETCH SUCCESS!')
        return;
      } else {
      }
    });



  }


}

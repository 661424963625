import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-sell-details',
  templateUrl: './sell-details.component.html',
  styleUrls: ['./sell-details.component.scss', '../select-item/select-item.component.scss'],
})
export class SellDetailsComponent implements OnInit {
  selected = 'Remanufactured';
  constructor(
    public commonService: CommonService
  ) { }

  ngOnInit() { }

  openVideoPopup() {
    let dialogRef = this.commonService.openVideoPopup()
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss'],
})
export class RequestsComponent implements OnInit {
  selectedIndex = 0;
  constructor(public commonService: CommonService) {}

  ngOnInit() {}

  onTabChanged(event) {
    this.selectedIndex = event.index;
  }
}

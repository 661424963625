import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { StoreService } from 'src/app/services/store.service';
import { MatDialog } from '@angular/material/dialog';
import { ReportListingModelComponent } from '../../report-listing-model/report-listing-model.component';
import { AddressDialogComponent } from '../../address-dialog/address-dialog.component';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-my-cart',
  templateUrl: './my-cart.component.html',
  styleUrls: ['./my-cart.component.scss'],
})
export class MyCartComponent implements OnInit {

  selectCountryValue: any;
  selectStateValue: any;
  userAddressesList: any = [];
  address: any = {};
  addNewAddress = true;
  isLoggedIn = false;
  constructor(
    public toaster: ToastrService,
    public store: StoreService,
    private toastr: ToastrService,
    public dialog: MatDialog,

    public auth: AuthService
  ) {
    this.store.viewCart();
    if (localStorage.getItem('currentUserProfile')) {
      this.isLoggedIn = true;
      var t = JSON.parse(localStorage.getItem('currentUserProfile'));
      this.getUserAddressesList();

    }

  }

  toggleAddressForm() {
    console.log('Address form toggled', this.addNewAddress);
    this.addNewAddress = !this.addNewAddress;
  }
  getUserAddressesList() {
    this.store.getUserAddressList().subscribe((res) => {
      this.userAddressesList = res;
    });
  }

  mapAddressToCart(id, index) {
    let data = {
      addressId: id,
    };
    this.store.mapAddressToCart(data).subscribe((res) => {
      this.toaster.success('Shipping address changed!');
      localStorage.setItem('userAddress', JSON.stringify(this.userAddressesList[index]));
      this.store.viewCart();
    });
  }
  mapBillingAddressToCart(id, index) {
    let data = {
      addressId: id,
    };
    this.store.mapBillingAddressToCart(data).subscribe((res) => {
      this.toaster.success('Billing address changed!');
      localStorage.setItem('userAddress', JSON.stringify(this.userAddressesList[index]));
      this.store.viewCart();
    });
  }

  // abjfd
  ngOnInit() {
    this.store.viewCart();
    this.address.addressType = "DEFAULT_SHIPPING";

    if (localStorage.getItem('currentUserProfile')) {
      this.isLoggedIn = true;

      var t = JSON.parse(localStorage.getItem('currentUserProfile'));
      this.getUserAddressesList();
    }


  }
  isVisible: any = false;
  changeShippingMethod(val) {
    if (val === 'Freight') {
      this.isVisible = false;
    } else {
      this.isVisible = true;
    }
  }
  productDetails: any;
  addToCart() {
    let cartId = localStorage.getItem('cartId');
    let data = {
      cartId: cartId ? cartId : '',
      dealId: this.productDetails.dealId ? this.productDetails.dealId : '',
      discountCode: this.productDetails.discountCode
        ? this.productDetails.discountCode
        : '',
      offer: this.productDetails.offer ? this.productDetails.offer : '',
      productId: this.productDetails.productId,
      quantity: 1,
    };
    this.store.addToCart(data).subscribe((res: any) => {
      if (res) {
        localStorage.setItem('cartId', res.cartId);
        this.store.viewCart();
        this.toastr.success('Added to cart');
      }
    }, err => {
      this.store.totalCart$ = 0;
      this.store.cart$ = [];
      this.store.cartProducts$ = [];
      localStorage.removeItem('cartId');
    })
  }
  prodCount: any;
  checkInCart(product: any) {
    if (this.store.cartProducts$ && this.store.cartProducts$.length) {
      for (var i = 0; i < this.store.cartProducts$.length; i++) {
        if (
          product.productId === this.store.cartProducts$[i].productDto.productId
        ) {
          const element = this.store.cartProducts$[i];
          this.prodCount = element.quantity;
        }
      }
    } else {
      this.prodCount = 0;
    }
    return this.prodCount;
  }
  addQuantity(product) {
    let cartId = localStorage.getItem('cartId');
    let prodQuantity: any;
    for (let i = 0; i < this.store.cartProducts$.length; i++) {
      if (
        product.productId === this.store.cartProducts$[i].productDto.productId
      ) {
        prodQuantity = this.store.cartProducts$[i];
      }
    }
    if (prodQuantity) {
      let data = {
        cartId: cartId ? cartId : '',
        dealId: product.dealId ? product.dealId : '',
        discountCode: product.discountCode ? product.discountCode : '',
        offer: product.offer ? product.offer : '',
        productId: product.productId,
        quantity: ++prodQuantity.quantity,
      };
      this.store.addToCart(data).subscribe((res: any) => {
        if (res) {
          this.store.viewCart();
        }
      }, err => {
        this.store.totalCart$ = 0;
        this.store.cart$ = [];
        this.store.cartProducts$ = [];
        localStorage.removeItem('cartId');
      });
    }
  }
  minusQuantity(product) {
    let cartId = localStorage.getItem('cartId');
    let prodQuantity: any;
    console.log(product);

    for (let i = 0; i < this.store.cartProducts$.length; i++) {
      if (
        product.productId === this.store.cartProducts$[i].productDto.productId
      ) {
        prodQuantity = this.store.cartProducts$[i];
      }
    }
    if (prodQuantity) {
      let i = prodQuantity.quantity;
      if (i !== 0) {
        i--;
        i = i;
        let data = {
          cartId: cartId ? cartId : '',
          dealId: product.dealId ? product.dealId : '',
          discountCode: product.discountCode ? product.discountCode : '',
          offer: product.offer ? product.offer : '',
          productId: product.productId,
          quantity: i,
        };
        this.store.addToCart(data).subscribe((res: any) => {
          if (res) {
            this.store.viewCart();
          }
        }, err => {
          this.store.totalCart$ = 0;
          this.store.cart$ = [];
          this.store.cartProducts$ = [];
          localStorage.removeItem('cartId');
        });
      }
      if (i === 0) {
        let data = {
          cartId: cartId ? cartId : '',
          discountCode: product.discountCode ? product.discountCode : '',
          quantity: 0,
          isOffer: product.hasOffer,
          dealId: product.dealId,
          productId: product.productId,
        };
        // this.store.removeFormCart(data).subscribe((res) => {
        //   console.log(res);
        // });
      }
    }
  }
  clearCart() {
    let cartId = localStorage.getItem('cartId');
    for (let i = 0; i < this.store.cartProducts$.length; i++) {
      const element = this.store.cartProducts$[i];
      let data = {
        cartId: cartId ? cartId : '',
        discountCode: element.productDto.discountCode
          ? element.productDto.discountCode
          : '',
        quantity: 0,
        isOffer: element.productDto.hasOffer,
        productId: element.productDto.productId,
      };
      this.store.addToCart(data).subscribe((res) => {
        this.store.viewCart();
      });
    }
    this.store.totalCart$ = 0;
    this.toaster.success('Cart Cleared!');
  }
  // Address Dialog
  openEditDialog() {
    console.log('Open Address dialog');
    const dialogRef = this.dialog.open(AddressDialogComponent, {
      width: '860px',
      data: { title: 'Edit Address' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }
  openAddAddressDialog() {
    console.log('Open Address dialog');
    const dialogRef = this.dialog.open(AddressDialogComponent, {
      width: '860px',
      data: { title: 'Add New Address' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }



  countries: any = ['MEXICO'];
  states: any = ['Mexico City,', 'Tijuana', 'Monterrey', 'Puebla'];
  contactInformationForm(form: any) {
    let data: any = {
      addLine1: form.value.address1,
      addLine2: form.value.address1,
      addState: this.selectStateValue,
      addressType: this.address.addressType,
      city: form.value.city,
      country: this.selectCountryValue,
      customerFullName: form.value.firstName + ' ' + form.value.lastName,
      zipCode: form.value.zipCode,
    };


    this.store.addCustomerAddress(data).subscribe((res) => {
      if (res) {
        this.toaster.success('Address added!');
        this.getUserAddressesList();
        form.reset();
        this.addNewAddress =true;
        this.selectCountryValue = null;
        this.selectStateValue = null;
      } else {
        this.toaster.warning('Something went wrong');
      }
      return this.userAddressesList;
    });
  }

  deleteAddress(addressId) {
    let data = {
      addressId: addressId,
    };
    this.store.deleteUserAddress(data).subscribe((res: any) => {
      if (res === true) {
        this.toaster.success('Address deleted!');
        this.getUserAddressesList();
      } else {
        this.toaster.warning('Something went wrong');
        this.getUserAddressesList();
      }
    });
  }
  changeCountry(val) {
    this.selectCountryValue = val.detail.value;
  }
  changeState(val) {
    this.selectStateValue = val.detail.value;
  }
}

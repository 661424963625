import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { StoreService } from 'src/app/services/store.service';
import * as $ from 'jquery';
import * as moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  TAB_VALUE = 'CORPORATE';
  profilePicVal: any;
  firstname: any;
  lastname: any;
  email: any;
  dob: any;
  model: any = {};
  constructor(
    public store: StoreService,
    public auth: AuthService,
    private toastr: ToastrService,

    private spinner1: NgxSpinnerService,
  ) {


  }

  ngOnInit() {
    //this.getUserProfile();
    this.onLoad();
  }

  onLoad() {
    this.spinner1.show();
    const currentUserProfile = JSON.parse(
      localStorage.getItem('currentUserProfile')
    );

    this.profileArr = currentUserProfile;


    this.model = {
      firstname: currentUserProfile.userFirstName,
      lastname: currentUserProfile.userLastName,
      email: currentUserProfile.emailAddr,
      dob: new Date(currentUserProfile.dob * 1000)
    };


    setTimeout(() => {
      this.setInputDate("#dob");

      this.spinner1.hide();
    }, 3000);
  }
  setInputDate(_id) {
    var _dat = document.querySelector(_id);
    // alert(this.model.dob)
    var hoy = this.model.dob,
      d: any = hoy.getDate(),
      m: any = hoy.getMonth() + 1,
      y = hoy.getFullYear(),
      data;

    if (d < 10) {
      d = "0" + d;
    };
    if (m < 10) {
      m = "0" + m;
    };

    data = y + "-" + m + "-" + d;
    // alert(data);
    _dat.value = data;
    this.model.dob = data;
  }


  profileArr: any = [];
  getUserProfile() {
    let data = {

    }
    this.auth.viewUserProfile(data).subscribe((res: any) => {
      this.profileArr = res;
      this.model = {
        firstname: this.profileArr.firstName,
        lastname: this.profileArr.lastName,
        email: this.profileArr.email,
        dob: moment($("#dob").val(), 'yyyy-MM-DD').unix(),
      };
      alert(JSON.stringify(this.profileArr))


    });
  }
  activeTab(TAB_VALUE) {
    this.TAB_VALUE = TAB_VALUE;
  }
  uploadProfilePic() {
    let data = {
      file: this.profilePicVal,
    };
  }
  public uploadImage(event: any, imageForm): void {
    this.spinnerImg = true;

    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];

      /*shrink*/

      // Ensure it's an image
      if (file.type.match(/image.*/)) {
        console.log('An image has been loaded');

        // Load the image
        var reader = new FileReader();
        reader.onload = function (readerEvent: any) {
          var image = new Image();
          image.onload = function (imageEvent: any) {
            // Resize the image
            var canvas = document.createElement('canvas'),
              max_size = 544, // TODO : pull max size from a site config
              width = image.width,
              height = image.height;
            if (width > height) {
              if (width > max_size) {
                height *= max_size / width;
                width = max_size;
              }
            } else {
              if (height > max_size) {
                width *= max_size / height;
                height = max_size;
              }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d').drawImage(image, 0, 0, width, height);
            var dataUrl = canvas.toDataURL('image/jpeg');
            var dataURL = dataUrl;

            /* Utility function to convert a canvas to a BLOB */
            var BASE64_MARKER = ';base64,';
            if (dataURL.indexOf(BASE64_MARKER) == -1) {
              var parts = dataURL.split(',');
              var contentType = parts[0].split(':')[1];
              var raw = parts[1];

              return new Blob([raw], { type: contentType });
            }

            var parts = dataURL.split(BASE64_MARKER);
            var contentType = parts[0].split(':')[1];
            var raw = window.atob(parts[1]);
            var rawLength = raw.length;

            var uInt8Array = new Uint8Array(rawLength);

            for (var i = 0; i < rawLength; ++i) {
              uInt8Array[i] = raw.charCodeAt(i);
            }

            var resizedImage = new Blob([uInt8Array], { type: contentType });
            /* End Utility function to convert a canvas to a BLOB    */

            // $.event.trigger({
            //   type: 'imageResized',
            //   blob: resizedImage,
            //   url: dataUrl,
            // });
          };
          $('#profileImage').attr('src', readerEvent.target.result);
          //this.profileArr.uploadedImage= readerEvent.target.result;
        };
        reader.readAsDataURL(file);
      }
      /*shrink*/

      this.store.updateUserProfilePic(event).subscribe(
        (res: any) => {
          if (res) {
            this.spinnerImg = false;
            this.toastr.success(
              'Profile picture uploaded successfully!'
            );
            // this.auth.logout();
            localStorage.setItem('currentUserProfile', JSON.stringify(res));
            this.auth.getCurrentUser();
            imageForm.reset();
            setTimeout(() => {

              this.onLoad();
            }, 2000);
          } else {
            this.spinnerImg = false;
            this.toastr.success(res.message);
            return;
          }
        },
        (err: any) => {

          // this.spinner1.hide();
          // if (!this.common.checkValidAuthResponseCode(err)) {
          //   return;
          // }
          // if (err.error.text) {
          //   this.toastr.success(err.error.text);
          // } else {
          //   this.toastr.success(err.error.message);
          // }
        }
      );
    }
  }

  spinnerImg: any = false;
  submitForm(updateForm) {
    let data = {
      firstname: this.model.firstname,
      lastname: this.model.lastname,
      email: this.model.email,
      dob: moment(this.model.dob, 'yyyy-MM-DD').unix()
    };
    this.store.updateUserProfile(data).subscribe(
      (res: any) => {
        if (res) {
          this.toastr.success('Profile updated  successfully!');
          localStorage.setItem('currentUserProfile', JSON.stringify(res));
          this.auth.getCurrentUser();
          setTimeout(() => {
            this.onLoad();
          }, 2000);
        } else {
          this.toastr.success(res.message);
          return;
        }
      },
      (err: any) => {
        // this.spinner.hide();
        // if (!this.common.checkValidAuthResponseCode(err)) {
        //   return;
        // }
        // if (err.error.text) {
        //   this.toastr.success(err.error.text);
        // } else {
        //   this.toastr.success(err.error.message);
        // }
      }
    );
  }
}

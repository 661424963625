import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  constructor(
    public auth: AuthService,
    public store: StoreService,
    public router: Router
  ) {}
  categoryId: any;
  currentUser: any;
  @Input() hideSeconderyMenu;

  searchForm: FormGroup;
  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUserProfile'));
    this.searchForm = new FormGroup({
      searchInput: new FormControl(),
    });
    this.store.getCategories();
    this.store.viewCart();
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    if (t === null) {
      return;
    } else {
      this.auth.getCurrentUser();
    }
  }
  onSubmit() {
    this.router.navigate(['/browse-listing/ALL']);
  }
  getProducts(id, subId) {
    console.log(subId);
    let data = {
      categoryId: id,
      filter: 'HotSelling',
      subCategoryId: subId,
      pageNo: '0',
      pageSize: '10',
      searchString: '',
      sortOption: 'productId',
      sortOrder: 'ASC',
    };
    this.store.getProduuctsByCategoryId(data);
  }
}

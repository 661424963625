import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
})
export class FilterPanelComponent implements OnInit {
  val = { lower: 10, upper: 20 };

  constructor(
    public modalController: ModalController,
    public store: StoreService,
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) {}
  categoryId: any;
  subCategoryId: any;
  ngOnInit() {
    this.activatedRoute.params.subscribe((res) => {
      this.categoryId = res.categoryId;
      this.subCategoryId = res.subCategoryId;
    });
    if (this.categoryId === 'ALL') {
      this.getAllProducts();
    } else {
      this.getProducts(this.categoryId);
    }
  }
  getProducts(categoryId) {
    let data = {
      categoryId: categoryId,
      filter: 'HotSelling',
      subCategoryId: this.subCategoryId,
      pageNo: '0',
      pageSize: '1000',
      searchString: '',
      sortOption: 'productId',
      sortOrder: 'ASC',
    };
    this.store.getProduuctsByCategoryId(data);
  }
  getAllProducts() {
    let data = {
      categoryId: '',
      filter: 'TextSearch',
      subCategoryId: '',
      pageNo: '0',
      pageSize: '1000',
      searchString: '',
      filterEx: {},
      showAllProducts: true,
      sortOption: 'productId',
      sortOrder: 'ASC',
    };
    this.store.getProduuctsByCategoryId(data);
  }
  close() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}

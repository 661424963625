import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-report-listing-model',
  templateUrl: './report-listing-model.component.html',
  styleUrls: ['./report-listing-model.component.scss'],
})
export class ReportListingModelComponent implements OnInit {

  title = "Report listing";
  description: 'Please provide the reasons to report this listing'

  constructor(
    @Optional() public dialogRef: MatDialogRef<ReportListingModelComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    if (this.data && this.data.title) {
      this.title = this.data.title;
    }
    if (this.data && this.data.description) {
      this.description = this.data.description;
    }
  }

}

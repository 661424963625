import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss'],
})
export class GoogleMapComponent implements OnInit {
  lat = 37.7749;
  lng = -122.4194;
  zoom: number = 12;
  draggable = false;
  gestureHandling: 'none';
  zoomControl: true;
  scrollwheel: false;
  scaleControl: false;

  coordinate = [
    {
      lat: 37.7749,
      lng: -122.4194,
      label: 'A',
      place: 'Industrial Street',
      number: '',
    },
    {
      lat: 37.752649,
      lng: -122.484883,
      label: 'B',
      place: 'Outer Sunset',
      number: '',
    },
    {
      lat: 37.753148,
      lng: -122.429445,
      label: 'C',
      place: 'Noe Valley',
      number: '',
    },
    {
      lat: 37.780778,
      lng: -122.281432,
      label: 'D',
      place: '555 Atlantic Ave',
      number: '',
    },
  ];

  constructor() {}

  ngOnInit() {}
}

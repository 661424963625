import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BidAndBuyModelComponent } from '../bid-and-buy-model/bid-and-buy-model.component';
import { ReportListingModelComponent } from '../report-listing-model/report-listing-model.component';
import { Lightbox } from 'ngx-lightbox';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreService } from 'src/app/services/store.service';
import { Product } from 'src/app/models/product.model';
// import { StorageService } from 'src/app/services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-product-detail-section',
  templateUrl: './product-detail-section.component.html',
  styleUrls: ['./product-detail-section.component.scss'],
})
export class ProductDetailSectionComponent implements OnInit {
  rate = 5;
  productDetails: any;
  slideOpts = {
    initialSlide: 1,
    speed: 1000,
    autoplay: {
      delay: 3000,
    },
  };

  constructor(
    public dialog: MatDialog,
    public _lightbox: Lightbox,
    public activatedRoute: ActivatedRoute,
    public store: StoreService,
    // private storageService: StorageService,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public auth: AuthService,
    private router: Router
  ) {
    this.store.viewCart();
  }
  productId: any;
  navigateToImg(index) {}
  ngOnInit() {
    this.spinner.show();
    this.activatedRoute.queryParams.subscribe((res) => {
      this.productId = res.productId;
      let data = {
        productId: this.productId,
      };
      this.store.getProductById(data).subscribe((res) => {
        this.spinner.hide();
        this.productDetails = res;
      });
    });
    // const myArray = this.activatedRoute.snapshot.queryParamMap.get('myArray');
    // if (myArray === null) {
    //   this.productDetails = new Array<string>();
    // } else {
    //   return (this.productDetails = JSON.parse(myArray));
    // }
  }
  sendMessage() {
    const dialogRef = this.dialog.open(ReportListingModelComponent, {
      width: '860px',
      data: { title: 'Send Message', description: 'Send Message Description' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  report() {
    const dialogRef = this.dialog.open(ReportListingModelComponent, {
      width: '860px',
      data: {
        title: 'Report listing',
        description: 'Please provide the reasons to report this listing',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  bidAndBuy() {
    if (this.auth.currentUser$) {
      const dialogRef = this.dialog.open(BidAndBuyModelComponent, {
        width: '860px',
        data:  this.productDetails ,
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log('The dialog was closed');
      });
    } else {
      this.router.navigate(['/tabs/login']);
    }
  }

  _albums: any = [];
  openPopup() {
    for (let i = 0; i < this.productDetails.listMedia.length; i++) {
      const element = this.productDetails.listMedia[i];
      let objs = {
        src: element.mediaURL,
        caption: 'Caption',
        thumb: element.mediaURL,
      };

      this._albums.push(objs);
    }
    console.log(this._albums);
    this._lightbox.open(
      this._albums.slice(4, this.productDetails.listMedia.length),
      0
    );
  }
  addToCart() {
    let cartId = localStorage.getItem('cartId');
    let data = {
      cartId: cartId ? cartId : '',
      dealId: this.productDetails.dealId ? this.productDetails.dealId : '',
      discountCode: this.productDetails.discountCode
        ? this.productDetails.discountCode
        : '',
      offer: this.productDetails.offer ? this.productDetails.offer : '',
      productId: this.productDetails.productId,
      quantity: 1,
    };
    this.store.addToCart(data).subscribe((res: any) => {
      if (res) {
        localStorage.setItem('cartId', res.cartId);
        this.store.viewCart();
        this.toastr.success('Added to cart!');
      }
    });
  }
  prodCount: any = 0;
  checkInCart(product: any) {
    let prodCount;
    if (this.store.cartProducts$ && this.store.cartProducts$.length) {
      for (var i = 0; i < this.store.cartProducts$.length; i++) {
        if (
          product.productId === this.store.cartProducts$[i].productDto.productId
        ) {
          const element = this.store.cartProducts$[i];
          prodCount = element.quantity;
        }
      }
    } else {
      prodCount = 0;
    }
    return prodCount;
  }
  addQuantity(product) {
    let cartId = localStorage.getItem('cartId');
    let prodQuantity: any;
    for (let i = 0; i < this.store.cartProducts$.length; i++) {
      if (
        product.productId === this.store.cartProducts$[i].productDto.productId
      ) {
        prodQuantity = this.store.cartProducts$[i];
      }
    }
    if (prodQuantity) {
      let data = {
        cartId: cartId ? cartId : '',
        dealId: product.dealId ? product.dealId : '',
        discountCode: product.discountCode ? product.discountCode : '',
        offer: product.offer ? product.offer : '',
        productId: product.productId,
        quantity: ++prodQuantity.quantity,
      };
      this.store.addToCart(data).subscribe((res: any) => {
        if (res) {
          this.store.viewCart();
          this.toastr.success('Added to cart!');
        }
      });
    }
  }
  minusQuantity(product) {
    let cartId = localStorage.getItem('cartId');
    let prodQuantity: any;
    for (let i = 0; i < this.store.cartProducts$.length; i++) {
      if (
        product.productId === this.store.cartProducts$[i].productDto.productId
      ) {
        prodQuantity = this.store.cartProducts$[i];
      }
    }
    if (prodQuantity) {
      let i = prodQuantity.quantity;
      if (i !== 0) {
        i--;
        i = i;
        let data = {
          cartId: cartId ? cartId : '',
          dealId: product.dealId ? product.dealId : '',
          discountCode: product.discountCode ? product.discountCode : '',
          offer: product.offer ? product.offer : '',
          productId: product.productId,
          quantity: i,
        };
        this.store.addToCart(data).subscribe((res: any) => {
          if (res) {
            this.toastr.error('Removed form cart!');
            this.store.viewCart();
          }
        });
      }
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss'],
})
export class AddressesComponent implements OnInit {
  constructor(
    private store: StoreService,
    public auth: AuthService,
    private toaster: ToastrService
  ) { }
  selectCountryValue: any;
  selectStateValue: any;
  userAddressesList: any = [];
  address: any = {};
  ngOnInit() {
    this.address.addressType = "SHIPPING";
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    if (t.accessToken === null) {
      this.auth.logout()
      return;
    } else {
      this.getUserAddressesList();
    }
  }
  countries: any = ['MEXICO'];
  states: any = ['Mexico City,', 'Tijuana', 'Monterrey', 'Puebla'];
  contactInformationForm(form: any) {
    let data: any = {
      addLine1: form.value.address1,
      addLine2: form.value.address1,
      addState: this.selectStateValue,
      addressType: this.address.addressType,
      city: form.value.city,
      country: this.selectCountryValue,
      customerFullName: form.value.firstName + ' ' + form.value.lastName,
      zipCode: form.value.zipCode,
    };


    this.store.addCustomerAddress(data).subscribe((res) => {
      if (res) {
        this.toaster.success('Address added!');
        this.getUserAddressesList();
      } else {
        this.toaster.warning('Something went wrong');
      }
      return this.userAddressesList;
    });
  }
  getUserAddressesList() {
    this.store.getUserAddressList().subscribe((res) => {
      this.userAddressesList = res;
      console.log(this.userAddressesList, 'ukbjkukj');
    });
  }
  deleteAddress(addressId) {
    let data = {
      addressId: addressId,
    };
    this.store.deleteUserAddress(data).subscribe((res: any) => {
      if (res === true) {
        this.toaster.success('Address deleted!');
        this.getUserAddressesList();
      } else {
        this.toaster.warning('Something went wrong');
        this.getUserAddressesList();
      }
    });
  }
  changeCountry(val) {
    this.selectCountryValue = val.detail.value;
  }
  changeState(val) {
    this.selectStateValue = val.detail.value;
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { StoreService } from 'src/app/services/store.service';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-bid-and-buy-model',
  templateUrl: './bid-and-buy-model.component.html',
  styleUrls: ['./bid-and-buy-model.component.scss'],
})
export class BidAndBuyModelComponent implements OnInit {
  TAB_SELECTED = 'BID';
  inputVal: any = '';
  constructor(private router: Router, private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public product: any,

    private auth: AuthService,
    public store: StoreService,
    public toastController: ToastController,
    private spinner: NgxSpinnerService,
    public toaster: ToastrService
  ) {


  }
  price: any = {};


  ngOnInit() {
   this.price.bid = 0;
    this.price.shipping = 0;

  }
  fillValue() {
    this.inputVal = '$119';
  }
  bidBuySelect(event) {
    this.TAB_SELECTED = event.detail.value;
  }
  async successToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      cssClass: 'custom-success-toast',
    });
    toast.present();
  }
  placeBid() {

    let data = {
      "bidPrice": this.price.bid,
      "productId": this.product.productId,

    };
    this.store.placeBid(data).subscribe((res: any) => {
      if (res) {
        console.log(res);
        this.successToast('PLACE BID SUCCESS!')
        this.router.navigate(['/product-dashboard/order']).then((res) => {
          this.matDialog.closeAll();
        });
        return;
      }
    });


  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-browse-by-manufacturer',
  templateUrl: './browse-by-manufacturer.component.html',
  styleUrls: ['./browse-by-manufacturer.component.scss'],
})
export class BrowseByManufacturerComponent implements OnInit {

  @ViewChild('slideWithNav2', { static: false }) slideWithNav2: IonSlides;

  slideOptsTwo = {
    loop: true,
    navigation: true,
    autoplay:true,
  };
  next(object, slideView) {
    slideView.slideNext();
    // console.log('Next');
  }

  prev(object, slideView) {
    slideView.slidePrev();
    // console.log('Prev');
  }

  constructor() { }

  brands = [
    {
      path: '../../../assets/brands/volvo.jpg',
      name: 'Volvo'
    },
    {
      path: '../../../assets/brands/mack.png',
      name: 'Mack'
    },
    {
      path: '../../../assets/brands/freightliner.png',
      name: 'Freightliner'
    },
    {
      path: '../../../assets/brands/westernStar.png',
      name: 'Western Star'
    },
    {
      path: '../../../assets/brands/peterLift.png',
      name: 'Peterlift'
    },
    {
      path: '../../../assets/brands/Toyota.png',
      name: 'Toyota'
    },
    {
      path: '../../../assets/brands/honda.png',
      name: 'Honda'
    },
    {
      path: '../../../assets/brands/GM.png',
      name: 'General Motors'
    }
  ]
  ngOnInit() { }

}

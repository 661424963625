import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';

import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-sell-item',
  templateUrl: './sell-item.component.html',
  styleUrls: ['./sell-item.component.scss'],
})
export class SellItemComponent implements OnInit {

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  isEditable = false;
  @ViewChild('stepper') stepper: MatStepper;

  constructor(

    private router: Router,
    private auth: AuthService,
    public store: StoreService,
    public toastController: ToastController,
    private spinner: NgxSpinnerService,
    public toaster: ToastrService,
    private _formBuilder: FormBuilder
  ) {
    this.getStoreKey();
    this.getStoreCategories();
  }
  ngOnInit() {
    
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    this.getStoreKey();
    this.getStoreCategories();

  }


  ionViewDidEnter() {
    this.getStoreKey();
    this.getStoreCategories();

  }

  merchantStoreData = null;

  getStoreKey() {
    this.store.getStoreKey().subscribe((res: any) => {
      if (res) {
        console.log(res);
        this.merchantStoreData = res;
        //this.successToast('STORE KEY  FETCH SUCCESS!')
        return;
      } else {
      }
    });
  }

  cat = null;
  getStoreCategories() {
    this.store.getStoreCategories().subscribe((res: any) => {
      if (res) {
        console.log(res);
        this.cat = res.Categories[0];
        // this.successToast('CATEGORY ID FETCH SUCCESS!')
        //  this.getProducts();
        return;
      } else {
      }
    });
  }
  async successToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      cssClass: 'custom-success-toast',
    });
    toast.present();
  }
  addNewProduct() {

    this.store.addNewProduct(this.cat, this.merchantStoreData).subscribe((res: any) => {
      if (res) {
        console.log(res);
        this.successToast('ADD NEW PRODUCT SUCCESS!');
        //  this.getProducts();
        return;
      } else {
      }
    });
  }
 

  stepperGoto(i) {
    this.stepper.selectedIndex = i
  }

}

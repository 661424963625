import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss'],
})
export class ReportListComponent implements OnInit {
  displayedColumns: string[] = [
    'icon',
    'listing',
    'reportedby',
    'date',
    'reason',
    'remove',
    'hide',
  ];
  dataSource;
  selectedValue = 'Reported';
  constructor(
    public commonService: CommonService,
    private alertController: AlertController
  ) {}
  async removeModal() {
    const alert = await this.alertController.create({
      cssClass: 'remove-alert-class',
      header: 'Confirm!',
      message: 'Do you want to remove?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          },
        },
        {
          text: 'Okay',
          handler: () => {
            console.log('Confirm Okay');
          },
        },
      ],
    });

    await alert.present();
  }
  async hideModal() {
    const alert = await this.alertController.create({
      cssClass: 'hide-alert-class',
      header: 'Confirm!',
      message: 'Do you want to hide?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          },
        },
        {
          text: 'Okay',
          handler: () => {
            console.log('Confirm Okay');
          },
        },
      ],
    });

    await alert.present();
  }
  ngOnInit() {
    this.dataSource = [
      {
        listing: 'Freightliner Classic XL Hood',
        reportedby: 'John Roberts',
        date: '02/02/2021',
        reason:
          'Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        slice: 39,
      },
      {
        listing: 'Freightliner Classic XL Hood',
        reportedby: 'John Roberts',
        date: '02/02/2021',
        reason:
          'Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        slice: 39,
      },
      {
        listing: 'Freightliner Classic XL Hood',
        reportedby: 'John Roberts',
        date: '02/02/2021',
        reason:
          'Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        slice: 39,
      },
      {
        listing: 'Freightliner Classic XL Hood',
        reportedby: 'John Roberts',
        date: '02/02/2021',
        reason:
          'Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        slice: 39,
      },
      {
        listing: 'Freightliner Classic XL Hood',
        reportedby: 'John Roberts',
        date: '02/02/2021',
        reason:
          'Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        slice: 39,
      },
      {
        listing: 'Freightliner Classic XL Hood',
        reportedby: 'John Roberts',
        date: '02/02/2021',
        reason:
          'Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        slice: 39,
      },
      {
        listing: 'Freightliner Classic XL Hood',
        reportedby: 'John Roberts',
        date: '02/02/2021',
        reason:
          'Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        slice: 39,
      },
      {
        listing: 'Freightliner Classic XL Hood',
        reportedby: 'John Roberts',
        date: '02/02/2021',
        reason:
          'Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        slice: 39,
      },
      {
        listing: 'Freightliner Classic XL Hood',
        reportedby: 'John Roberts',
        date: '02/02/2021',
        reason:
          'Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        slice: 39,
      },
      {
        listing: 'Freightliner Classic XL Hood',
        reportedby: 'John Roberts',
        date: '02/02/2021',
        reason:
          'Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        slice: 39,
      },
    ];
  }

  selectReportType(event) {
    this.selectedValue = event.detail.value;
  }

  showMore(index) {
    console.log(index);
    if (this.dataSource[index].slice == -100) {
      this.dataSource[index].slice = 39;
    } else {
      this.dataSource[index].slice = -100;
    }
  }
}

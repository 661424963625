import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { single } from './data';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

  single: any[];
  multi: any[];

  view: any[] = [700, 400];
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = 'Year';
  showYAxisLabel = true;
  yAxisLabel = 'Sales';
  yAxisTickFormatting(value) {
    return "$" + value;
  }
  onResize(event) {
    this.view = [event.target.innerWidth / 1.35, 400];
     // alert("hi");
   }
  colorScheme = {
    domain: ['#85fbff', '#85fbff', '#85fbff', '#85fbff']
  };




  displayedColumns: string[] = [
    // 'checkbox',
    'product_name',
    'product_id',
    'attribute',
    'availability',
    'retaile_price',
    'sale_price',
    'active',
  ];
  dataSource;

  constructor(public commonService: CommonService, public auth: AuthService, platform: Platform) {
    Object.assign(this, { single })
    console.log(platform.width())

    let width = 700;

    if (platform.width() < 750) {
      width = 300;
    }
    this.view = [width, 400];
  }
  ngOnInit() {
    this.dataSource = [
      {
        product_name: 'Product name',
        product_id: 'product id',
        attribute: 'attribute',
        availability: 'availability',
        retaile_price: 'retaile price',
        sale_price: 'sale price',
        active: 'active',
      },
      {
        product_name: 'Product name',
        product_id: 'product id',
        attribute: 'attribute',
        availability: 'availability',
        retaile_price: 'retaile price',
        sale_price: 'sale price',
        active: 'active',
      },
      {
        product_name: 'Product name',
        product_id: 'product id',
        attribute: 'attribute',
        availability: 'availability',
        retaile_price: 'retaile price',
        sale_price: 'sale price',
        active: 'active',
      },
      {
        product_name: 'Product name',
        product_id: 'product id',
        attribute: 'attribute',
        availability: 'availability',
        retaile_price: 'retaile price',
        sale_price: 'sale price',
        active: 'active',
      },
      {
        product_name: 'Product name',
        product_id: 'product id',
        attribute: 'attribute',
        availability: 'availability',
        retaile_price: 'retaile price',
        sale_price: 'sale price',
        active: 'active',
      },
      {
        product_name: 'Product name',
        product_id: 'product id',
        attribute: 'attribute',
        availability: 'availability',
        retaile_price: 'retaile price',
        sale_price: 'sale price',
        active: 'active',
      },
      {
        product_name: 'Product name',
        product_id: 'product id',
        attribute: 'attribute',
        availability: 'availability',
        retaile_price: 'retaile price',
        sale_price: 'sale price',
        active: 'active',
      },
      {
        product_name: 'Product name',
        product_id: 'product id',
        attribute: 'attribute',
        availability: 'availability',
        retaile_price: 'retaile price',
        sale_price: 'sale price',
        active: 'active',
      },
      {
        product_name: 'Product name',
        product_id: 'product id',
        attribute: 'attribute',
        availability: 'availability',
        retaile_price: 'retaile price',
        sale_price: 'sale price',
        active: 'active',
      },
    ];
  }


  // Charts
  onSelect(event) {
    console.log(event);
  }
}

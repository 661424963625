import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { StoreService } from 'src/app/services/store.service';
@Component({
  selector: 'app-no-listing',
  templateUrl: './no-listing.component.html',
  styleUrls: ['./no-listing.component.scss'],
})
export class NoListingComponent implements OnInit {
  rate = 4;
  constructor(public commonService: CommonService,

    private router: Router,
    private auth: AuthService,
    public store: StoreService,
    public toastController: ToastController,
    private spinner: NgxSpinnerService,
    public toaster: ToastrService
  ) {
    this.getStoreKey();
    this.getStoreCategories();
  }
  async successToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      cssClass: 'custom-success-toast',
    });
    toast.present();
  }

  cat = "";

  listedProducts = [];
  ionViewDidEnter() {
    this.getStoreKey();
    this.getStoreCategories();
  }

  ngOnInit() {

    this.getStoreKey();
    this.getStoreCategories();
  }
  getNewStore() {
    var t = JSON.parse(localStorage.getItem('currentUserProfile'));
    let data1 = {
      email: t.email,
      loginToken: localStorage.getItem('loginToken'),
      password: t.emailAddr,
      username: t.emailAddr,
    };

    try {
      this.auth.addNewStore(data1).subscribe((res2: any) => {
        this.successToast('STORE SETUP SUCCESS!')
        // this.getStoreKey();
        this.merchantStoreData = res2;
      });
    } catch (err) {
    } finally {
      // this.successToast('STORE SETUP FAILED!')
      // this.getStoreKey();
      //this.merchantStoreData = err;
    }

  }

  getStoreCategories() {
    this.store.getStoreCategories().subscribe((res: any) => {
      if (res) {
        console.log(res);
        this.cat = res.Categories[0];
        // this.successToast('CATEGORY ID FETCH SUCCESS!')
        this.getProducts();
        return;
      } else {
      }
    });
  }
  getStoreKey() {
    this.store.getStoreKey().subscribe((res: any) => {
      if (res) {
        console.log(res);
        this.merchantStoreData = res;
        //this.successToast('STORE KEY  FETCH SUCCESS!')
        return;
      } else {
      }
    });
  }

  fileToUpload: File | null = null;
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }
  merchantStoreData = null;

  getProducts() {
    let data = {
      categoryId: "",
      
      filter: "TextSearch",
      pageNo: 0,
      pageSize: 30,
      searchString: "MY NEW",
      sortOption: "productName",
      filterEx: {},
      showAllProducts: true,
      sortOrder: "ASC",
      storeId: this.merchantStoreData['storeId']

    };
    this.store.searchProduct(data).subscribe((res: any) => {
      if (res) {
        console.log(res);
        this.listedProducts = res.products;
        // this.successToast('PRODUCTS FETCH SUCCESS!')
        return;
      } else {
      }
    });
  }


}

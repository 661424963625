import { Component, OnInit } from '@angular/core';
import { AlertController, MenuController } from '@ionic/angular';
import { Platform } from '@ionic/angular';

import { AuthService } from './services/auth.service';
import { StoreService } from './services/store.service';
import { Location } from '@angular/common';
import { single } from './data';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public appPages = [
    { title: 'Post a Request', url: '/folder/Inbox', icon: 'mail' },
    { title: 'Browse Requests', url: '/folder/Outbox', icon: 'paper-plane' },
    { title: 'Browse Listings', url: '/folder/Favorites', icon: 'heart' },
    { title: 'Login', url: '/auth', icon: 'person' },
    { title: 'Sign up', url: '/tabs/sign-up', icon: 'person' },
  ];
  public labels = [
    'Hood & Front End',
    'Cab & Sleeper',
    'Engine',
    'Drivetrain',
    'Axle & Wheel End',
    'Chassis',
    'Steering & Suspension',
    'More',
  ];
  currentUser: any;

  // Charts
  single: any[];
  multi: any[];

  view: any[] = [700, 400];
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Country';
  showYAxisLabel = true;
  yAxisLabel = 'Population';

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'],
  };

  constructor(
    public menuCtrl: MenuController,
    public auth: AuthService,
    public store: StoreService,
    private platform: Platform,
    private _location: Location,
    public alertController: AlertController
  ) {


    // if (localStorage.getItem('currentUserProfile')) {
    //   this.auth.currentUser$ = JSON.parse(localStorage.getItem('currentUserProfile'));
    // } else {
    //   this.auth.currentUser$ = [];
    // }
    this.initializeApp();
    /* this.menuCtrl.swipeGesture(true, 'contentId') */
    // this.platform.backButton.subscribeWithPriority(0, () => {
    //   navigator['app'].exitApp();
    // });
    Object.assign(this, { single });
  }
  initializeApp() {
    this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
      console.log('Back press handler!');
      if (this._location.isCurrentPathEqualTo('/home')) {
        // Show Exit Alert!
        let i = 1;
        if (i != 2) {
          i++;
          let count = i;

          if (count == 2) {
            console.log(count);

            navigator['app'].exitApp();
          }
        }
        console.log('Show Exit Alert!');
        this.showExitConfirm();
        processNextHandler();
      } else {
        // Navigate to back page
        console.log('Navigate to back page');
        this._location.back();
      }
    });

    this.platform.backButton.subscribeWithPriority(5, () => {
      console.log('Handler called to force close!');
      this.alertController
        .getTop()
        .then((r) => {
          if (r) {
            navigator['app'].exitApp();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    });
  }

  showExitConfirm() {
    this.alertController
      .create({
        header: 'App termination',
        message: 'Do you want to close the app?',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Stay',
            role: 'cancel',
            handler: () => {
              console.log('Application exit prevented!');
            },
          },
          {
            text: 'Exit',
            handler: () => {
              navigator['app'].exitApp();
            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }
  ngOnInit() {
    let currentUserdata = JSON.parse(
      localStorage.getItem('currentUserProfile')
    );
    if (currentUserdata === null) {
      return;
    } else {
      this.auth.getCurrentUser();
    }
    this.menuCtrl.swipeGesture(true);
    const randomNumber: any = Math.floor(Math.random() * 1000000000);
    localStorage.setItem('deviceFingerPrint', randomNumber);
    // this.currentUser = JSON.parse(localStorage.getItem('currentUserProfile'));
    // this.store.viewLoginUser().subscribe((res) => {
    //   this.currentUser = res;
    // });
    this.store.viewCart();
  }
  title = 'My first AGM project';
  lat = 51.678418;
  lng = 7.809007;
  // getCurrentUser() {
  //   let data = JSON.parse(localStorage.getItem('currentUserProfile'));
  //   // console.log(this.currentUser);
  //   this.store.viewLoginUser(data).subscribe((res) => {
  //     this.currentUser = res;
  //   });
  // }
  menuClose() {
    this.menuCtrl.close();
  }
  getAllProducts() {
    let data = {
      categoryId: '',
      filter: 'TextSearch',
      subCategoryId: '',
      pageNo: '0',
      pageSize: '1000',
      searchString: '',
      sortOption: 'productId',
      filterEx: {},
      showAllProducts: true,
      sortOrder: 'ASC',
    };
    this.store.getProduuctsByCategoryId(data);
  }

  openCloseMenu(category) {
    if (!category.isCollapsed) {
      category.isCollapsed = true;
    } else {
      category.isCollapsed = false;
    }
  }
}
